import React from "react";
import {
  FormWrapper,
  FormContainer,
  FormOverlay,
  SubscribeHeader,
  SuccessfulWrapper,
} from "./styles";
import { Form, Checkbox, Spin } from "antd";
import { InputWrapper } from "../Input/style";
import SubmitButton from "../Button";
import background from "../../assets/images/customer-service.jpg";
import useSubscribeNewsletter from "../../hooks/useSubscribeNewsletter";

const SubscribeForm = ({ title, description }) => {
  const {
    error,
    emailAddress,
    firstName,
    handleCheckedChange,
    handleEmailAddressChange,
    handleNameChange,
    handleSubscribeFormSubmit,
    handleSuccessfulButtonClick,
    isChecked,
    isLoading,
    isSuccessful,
  } = useSubscribeNewsletter();

  return (
    <FormContainer backgroundImage={background}>
      <FormOverlay>
        <FormWrapper>
          {!isSuccessful ? (
            <>
              <div className="description">
                <h1>{title || "THERE’S MORE IN STORE"}</h1>
                <p>
                  {description ||
                    `Subscribe to our exclusive newsletter to enjoy lifestyle tips
                  and juicy stories on Insurance.`}
                </p>
              </div>
              <Form layout="vertical" onFinish={handleSubscribeFormSubmit}>
                <Form.Item>
                  <Form.Item
                    label="Email Address"
                    name="emailAddress"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputWrapper
                      name="emailAddress"
                      onChange={handleEmailAddressChange}
                      value={emailAddress}
                    />
                  </Form.Item>
                  <small>{error.email}</small>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[{ required: true }]}
                  >
                    <InputWrapper
                      name="firstName"
                      onChange={handleNameChange}
                      value={firstName}
                    />
                  </Form.Item>
                  <small>{error.username}</small>
                </Form.Item>
                <Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckedChange}
                    >
                      I agree to receive notifications, updates, and
                      publications from Heirs Insurance.
                    </Checkbox>
                  </Form.Item>
                  <small>{error.checked}</small>
                </Form.Item>
                <Form.Item>
                  <Form.Item>
                    {!isLoading ? (
                      <SubmitButton style={{ width: "100%" }} htmlType="submit">
                        Subscribe
                      </SubmitButton>
                    ) : (
                      <SubmitButton
                        style={{ width: "100%" }}
                        type="button"
                        disabled
                      >
                        <Spin size="small" />
                      </SubmitButton>
                    )}
                  </Form.Item>
                </Form.Item>
              </Form>
            </>
          ) : (
            <SuccessfulWrapper>
              <SubscribeHeader>Thank you!</SubscribeHeader>
              <p>You're subscribed to our latest news and updates.</p>
              <SubmitButton type="button" onClick={handleSuccessfulButtonClick}>
                Ok Got it!
              </SubmitButton>
            </SuccessfulWrapper>
          )}
        </FormWrapper>
      </FormOverlay>
    </FormContainer>
  );
};

export default SubscribeForm;
