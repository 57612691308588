import React from "react";
import Banner from "./Banner";
// import OurPhilosophy from "../../components/OurPhilosophy";
// import OurLeadership from "../../components/OurLeadership";
import CorporateGoverance from "../../components/CorporateGoverance";
import backgroundImage from "../../assets/images/corporate.jpg";

const Index = () => {
  const bannerPayload = {
    title: "Investors Relations",
    descriptions: [
      "We are a team of professionals who believe that there is a simpler and better way to do insurance.",
    ],
    backgroundImage,
  };
  return (
    <div>
      <Banner {...bannerPayload} />
      <CorporateGoverance />
    </div>
  );
};

export default Index;
