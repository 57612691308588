import React from "react";
import Banner from "./Banner";
import backgroundImage from "../../components/ProductBanner";
import { Wrapper } from "./styles";
import Form from "./Form";

const Index = () => {
  const bannerPayload = {
    title: "TriplePay Plan",
    backgroundImage,
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />
      <Form />
    </Wrapper>
  );
};

export default Index;
