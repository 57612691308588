import React from "react";
import ContentPage from "../../components/ContentPage";
import { Heading, Paragraph, Text } from "../../components/ContentPage/styles";
const Privacy = () => {
  return (
    <ContentPage title="Privacy">
      <Paragraph>
        <Heading>IMPORTANT INFORMATION</Heading>
        <Text>
          This page states the Terms and Conditions under which you may use the
          website &nbsp;
          <a href="http://www.heirsinsurance.com">www.heirsinsurance.com </a>or
          &nbsp;
          <a href="http://www.heirslifeassurance.com">
            www.heirslifeassurance.com{" "}
          </a>{" "}
          (referred to as the Site). Please read these Terms and Conditions
          carefully. If you do not accept the Terms and Conditions stated here,
          do not use the Site. Heirs Life Assurance reserves the right to revise
          the enclosed Terms and Conditions on a frequent basis. You as the
          viewer agree to review the Terms and Conditions on a periodical basis.
          As a repeat visitor to the Site, you may consider the Terms and
          Conditions binding to successive visits.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>INFORMATION ON THE SITE</Heading>
        <Text>
          This Site aims to provide commercial information to clients
          prospective and current. However, personal comments, opinions and
          views expressed on this Site are not necessarily a representation of
          the views of Heirs Life Assurance, its directors or its clients. Heirs
          Life Assurance is not responsible for any injury, loss, damage or
          expense incurred by any individual as a result either directly or
          indirectly of any information published on this website. In using this
          site, you acknowledge and agree that the terms set forth above are
          fundamental to the usage of the Site, and that the Site would not be
          provided to you in the absence of such terms.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>USER INTERACTIONS</Heading>
        <Text>
          Generally, any communication which you transfer to the Site is
          considered to be non-confidential. Heirs Life Assurance will not
          guarantee the privacy or confidentiality of any information relating
          to the user passing over the Internet.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>DISCLAIMER</Heading>
        <Text>
          Heirs Life Assurance does not claim the Site will operate free of
          errors or that the Site and its servers are free of possibly harmful
          elements.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>THIRD PARTY LINKS</Heading>
        <Text>
          The Site may contain links to third party Web Sites. These links are
          provided solely as a convenience to you. Heirs Insurance/ Heirs Life
          Assurance is not responsible for the content of linked third party
          sites and does not make any representations regarding the content or
          accuracy of materials on such third party Web Sites. If you decide to
          access linked third-party Web Sites, you do so at your own risk and in
          accordance with the prevailing terms and conditions and privacy
          policies of third party sites.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>GENERAL</Heading>
        <Text>
          Any disputes, claims or proceedings arising out of or in any way
          relating to the materials or the Site shall be governed by the laws of
          the Federal Republic of Nigeria. The Nigerian Courts shall have
          exclusive jurisdiction for the purpose of any proceedings arising out
          of or in any way relating to the materials or the Site. If any
          provision of this Agreement is found to be invalid by any court having
          competent jurisdiction, the invalidity of such provision shall not
          affect the validity of the remaining provisions of this Agreement,
          which shall remain in full force and effect. No waiver of any term of
          this Agreement shall be deemed a further or continuing waiver of such
          term or any other term. Heirs Life Assurance may at any time and
          without liability modify, suspend or discontinue the Site or any
          materials (or any part or specification thereof), with or without
          notice, for any valid technical, operational or commercial reasons.
          These Terms and Conditions constitute the entire agreement between you
          and Heirs Life Assurance with respect to the use of the Site.
        </Text>
      </Paragraph>
    </ContentPage>
  );
};

export default Privacy;
