/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Col, Modal, Form } from "antd";
import Icon from "@ant-design/icons";
import {
  PlanWrapper,
  Title,
  Text,
  LinksWrapper,
  LinksWrapperGrey,
  FormWrapper,
} from "../style";
import { Mixpanel } from "./../../../Mixpanel";
import FormInput from "../../../components/Input";
import axios from "axios";
import { HeirsEmailUrl } from "../../../utils/constant";
import { DatePickerWrapper } from "../../../components/Input/style";

const Plan = ({ title, icon, price, textmsg, url, mixpanel, quote }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  const handleClick = () => {
    Mixpanel.track(mixpanel);
    window.location.pathname = quote;
  };

  const handleDetails = () => {
    Mixpanel.track(mixpanel);
    window.open(quote);
    // window.location.pathname = quote;
    /*    
    Mixpanel.track("Send Message");
    const data = {
      firstName: "",
      lastName: "",
      email: email,
      phoneNumber: "",
      destination: [
        "wecare@heirslifeassurance.com",
        "nimiweremi.selekere@heirslifeassurance.com",
      ],
      message: date,
      plan: title,
    };
    axios({
      method: "POST",
      url: `${HeirsEmailUrl}/email/others`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          handleServerResponse(true, response.data.message);
          // setTimeout(() => {
          //   history.push("/flexi-motor");
          // }, 5000);
        } else {
          handleServerResponse(false, response.data.message);
        }
      })
      .catch((error) => {
        handleServerResponse(false, "An internal error occured");
      });
*/
  };

  const handleDateChange = (e) => {
    setDate(`Remind me on ${e._d}`);
  };

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
      Array.from(document.querySelectorAll("textarea")).forEach(
        (textarea) => (textarea.value = "")
      );
      // setIsDisabled(false);
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleEmailChange = (e) => {
    console.log("hh", e.target.value);
    setEmail(e.target.value);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
    // window.open(quote);
  };

  return (
    <>
      <Col xs={12} sm={8} md={6} lg={4}>
        <PlanWrapper>
          <a href={quote} onClick={handleClick}>
            <Icon component={icon} />
          </a>
          <Title>{title}</Title>
          {/* <Text>{price} minimum</Text>
          <LinksWrapper>
            <a href={quote} onClick={handleClick}>
              Buy Now
            </a>
          </LinksWrapper> */}
          <LinksWrapperGrey>
            <a onClick={showModal}>Details</a>
          </LinksWrapperGrey>
        </PlanWrapper>
      </Col>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        okText="Buy Now"
        onOk={() => handleDetails(title)}
      >
        <Col>
          <h1>{title}</h1>
        </Col>
        <FormWrapper>
          {textmsg}
          {/* <Col span={24} style={{ padding: 0 }}>
            <label htmlFor="email_address">Enter your email address</label>
            <FormInput
              type="email"
              name="email_address"
              placeholder="Email Address"
              onChange={handleEmailChange}
            />
          </Col>
          <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
            <Form.Item
              name="incidentDate"
              label="Reminder Date"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter date of reminder",
                },
              ]}
            >
              <DatePickerWrapper onChange={handleDateChange} />
            </Form.Item>
          </Col> */}
        </FormWrapper>
        {status.info.error && (
          <div className="error">Error: {status.info.msg}</div>
        )}
        {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
      </Modal>
    </>
  );
};

export default Plan;
