import React from "react";
import {
  Wrapper,
  Title,
  BannerContentWrapper,
  Overlay,
  Content,
} from "./bannerStyle";
const Banner = ({ title, backgroundImage, children, details, text }) => {
  return (
    <Overlay>
      <Wrapper>
        <Content backgroundImage={backgroundImage}>
          <BannerContentWrapper>
            <Title> {title}</Title>
            <p style={{ fontSize: "30pt" }}>{children}</p>
            <p style={{ paddingTop: "20px", width: "55%", fontSize: "14pt" }}>
              {details}
            </p>
          </BannerContentWrapper>
        </Content>
      </Wrapper>
    </Overlay>
  );
};

export default Banner;
