import React from "react";
import { Row, Col } from "antd";
import {
  Wrapper,
  Title,
  BannerContentWrapper,
  LinkWrapper,
  Overlay,
  BannerContent,
} from "./styles";
// import { Link } from "react-router-dom";
import { CaretRightFilled } from "@ant-design/icons";
import Lottie from "lottie-react-web";
import animationData from "../../assets/animations/home-banner.json";
const defaultOptions = {
  loop: false,
  animationData,
};
const Banner = ({ title, backgroundImage, linkPath, linkText, children }) => {
  return (
    <Overlay>
      <Wrapper>
        <Row>
          <Col xl={12} lg={14} md={12} sm={14} xs={14}>
            <BannerContentWrapper>
              <BannerContent>
                <Title> {title}</Title>
                {linkText && linkPath && (
                  <LinkWrapper>
                    <a href={linkPath}>
                      {linkText} <CaretRightFilled />
                    </a>
                  </LinkWrapper>
                )}
              </BannerContent>
            </BannerContentWrapper>
          </Col>
          <Col xl={12} lg={10} md={12} sm={10} xs={10}>
            <Lottie width="100%" options={defaultOptions} />
          </Col>
        </Row>
      </Wrapper>
    </Overlay>
  );
};

export default Banner;
