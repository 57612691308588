import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/group-banner.png";
import KeymanPlanPicker from "../../components/KeymanPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Keyman Assurance",
    descriptions: [
      "<p>This plan is usually taken by organizations or businesses to protect the life of a key staff or shareholder whose absence may cripple the business.</p>",
      "<p>The Heirs Keyman Assurance plan protects your business against financial losses that may arise as a result of the sudden death or permanent disability of a key staff or shareholder.</p>",
      "<p>In the case of death or permanent disability of this person, Heirs Life pays a bulk amount of money to your company. This bulk amount can be used to replace profit or cover the expensive cost to replace the key staff and cushion the financial blow on your company.</p>",
      "<p>This way, your organization/company does not go into bankruptcy.</p>",
      "<p><b>Who is a Keyman?</b></p>",
      "<p>1) A keyman is an employee or shareholder who makes significant contributions to the revenue of a business and whose demise or disability may cripple the business.</p>",
      "<p>2) For SMEs, the keyman is usually the owner or founder of the business</p>",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#keyman",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };

  return (
    <div>
      <Banner {...bannerPayload} />

      <KeymanPlanPicker />
    </div>
  );
};

export default Index;
