// import { CaretRightFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { ClaimText, LinkWrapper } from "../../components/ProductBanner/styles";
import {
  Wrapper,
  BannerContentWrapper,
  Content,
  SubTitle,
} from "./bannerStyle";

const Banner = ({
  title,
  descriptions = [],
  backgroundImage,
  linkPath,
  linkText,
  otherLinkPath,
  otherLinkText,
  children,
}) => {
  return (
    <Content backgroundImage={backgroundImage}>
      <Wrapper>
        <BannerContentWrapper>
          <SubTitle>{title}</SubTitle>
          {descriptions.map((description, key) => (
            <ClaimText key={key}>{description}</ClaimText>
          ))}

          <Row gutter={[4, 0]} style={{ width: "100%" }}>
            <Col sm={24} md={12} lg={4}>
              {linkText && linkPath && (
                <LinkWrapper>
                  <a href={linkPath}>{linkText}</a>
                </LinkWrapper>
              )}
            </Col>
            {/* <Col sm={24} md={12} lg={4}>
              {otherLinkText && otherLinkPath && (
                <LinkWrapper>
                  <a href={otherLinkPath}>
                    {otherLinkText} <CaretRightFilled />
                  </a>
                </LinkWrapper>
              )}
            </Col> */}
          </Row>
          {children}
        </BannerContentWrapper>
      </Wrapper>
    </Content>
  );
};

export default Banner;
