import React from "react";
import ContentPage from "../../components/ContentPage";
import { Heading, Paragraph, Text } from "../../components/ContentPage/styles";
// import { Heading } from '../../components/BlogContentPage/styles';
const Communication = () => {
  return (
    <ContentPage title="Communication Policy">
      <Paragraph>
        <Heading>1. Introduction</Heading>
        <Text>
          Heirs Life Assurance Limited (“the Company”) and its employees
          interact internally with each other and externally with external
          stakeholders in various ways. Such interaction could be in the form of
          emails, press releases, newsletters, advertorials, tweets, other
          social media communication, etc. Employees may also be invited to
          speak or participate in various public functions in an official
          capacity. In certain instances, third party vendors or contractors may
          also be required to communicate on behalf of the Company.
        </Text>

        <Text>
          In view of the foregoing, it is imperative that the principles that
          will govern any form of corporate communication in the Company are
          clearly defined.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>2. Purpose</Heading>
        <Text>The objectives of this Policy are as follows:</Text>
        <ul style={{ listStyle: "lower-roman" }}>
          <li>
            To encourage effective internal communication within the Company’s
            employees.{" "}
          </li>
          <li>
            To encourage effective communication with external stakeholders.
          </li>
          <li>
            To provide clear directions to all employees on how to communicate
            Company’s publicly available information.
          </li>
          <li>
            To help employees understand the Company’s communication style
            regarding non-public information as well as the people authorised to
            handle such information.
          </li>
          <li>To clarify the restrictions on communication with the media.</li>
          <li>
            To streamline the process of accepting public speaking appointments.
          </li>
          <li>
            To set out the principles governing the use of the social media by
            employees.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <Heading>3. Scope</Heading>
        <ul style={{ listStyle: "lower-roman" }}>
          <li>
            This Policy applies to all employees of the Company as it relates to
            any form of corporate communications.
          </li>
          <li>
            All vendors and contractors who are creating or contributing to
            blogs, social networking sites, discussion forums, or any other kind
            of social media on behalf of Heirs Life Assurance Limited.
          </li>
          <li>
            This Corporate Communications Policy shall be applicable in
            conjunction with the Brand Guidelines, the Crisis Management Policy,
            and the Human Resources Policy Manual in place from time to time.
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Heading>4. Responsibilities</Heading>
        <Text>
          The Head, Marketing & Corporate Communications (Head, MCC) shall have
          primary responsibility of implementing this Policy. The Head, MCC
          shall also be responsible for the day to day implementation and
          sensitisation of employees on the provisions of this Policy.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>5. Definition of Terms</Heading>

        <ul style={{ listStyle: "lower-roman" }}>
          <li>
            <strong>
              Affiliated Third Parties Restricted or Confidential information
            </strong>
            : information which is made available by the Managing Director/CEO
            or on the Managing Director/CEO’s directive to only select employees
            who by virtue of their position and authority need to know such
            information and disclosure of which can result in severe financial
            and reputational damage to the Company.
          </li>

          <li>
            <strong>Publicly available information</strong>: information that is
            already legitimately available in the public domain (for example,
            information that is available to all Company’s employees, available
            on the Company’s website or contained in the Company’s external
            publications and reports).
          </li>
          <li>
            <strong>Non-public information</strong> : all information that has
            not been made publicly available through media channels but has been
            disclosed internally to all Company’s employees.
          </li>
          <li>
            <strong>Media</strong>: include print media, social media, broadcast
            media, internet/wire news services, analyst reports and analysis,
            etc.
          </li>
          <li>
            <strong>Stakeholders</strong> : people, groups, or organisations
            that have direct or indirect stake in Heirs Life Assurance Limited
            because they can affect or be affected by Company’s actions,
            objectives, and policies.
          </li>
          <li>
            <strong>External stakeholders</strong> : include the media, rating
            agencies, regulators, vendors, government, governmental agencies,
            business partners, financial institutions, etc.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <Heading>6. External Communication</Heading>
        <strong>6.1. General</strong>
        <ul style={{ listStyle: "lower-roman" }}>
          <li>
            External stakeholders should be informed of major business events
            affecting the Company in a factual and timely manner.
          </li>

          <li>
            Heirs Life Assurance communicates and markets its different
            businesses to a diverse set of stakeholders that operate in
            different legal, regulatory, and cultural environment.
          </li>
          <li>
            Good and effective communication safeguards the Company’s
            reputation, results in supportive stakeholders, builds our brand and
            leads to sustainable value.
          </li>
          <li>
            Our communication must be in line with all regulatory requirements
            in all areas of our business.{" "}
          </li>
          <li>
            <strong>Stakeholders</strong> : people, groups, or organisations
            that have direct or indirect stake in Heirs Life Assurance Limited
            because they can affect or be affected by Company’s actions,
            objectives, and policies.
          </li>
          <li>
            <strong>External stakeholders</strong> : include the media, rating
            agencies, regulators, vendors, government, governmental agencies,
            business partners, financial institutions, etc.
          </li>
        </ul>
        <strong>6.2. Communication of publicly available information</strong>
        <li>
          Heirs Life Assurance supports the participation of all employees in
          helping to build our brand. However, this shall be done through
          knowledgeable and accurate communication of the Company’s key messages
          whether in business or social settings.
        </li>

        <li>
          Information shall be deemed to be publicly available if it has been
          officially communicated to all staff from the Executive Office by
          email or at staff meetings, unless otherwise stated.{" "}
        </li>
        <li>
          When presentations are prepared for internal or external use, the
          appropriate presentation template shall be used.
        </li>
        <li>
          All presentations prepared for external purposes shall be reviewed and
          approved by the Managing Director/CEO or by any senior management
          staff designated by the Managing Director/CEO.{" "}
        </li>

        <strong>6.3 Communication with Regulators</strong>
        <ul style={{ listStyle: "lower-roman" }}>
          <li>
            In most instances, communication with regulators is done in
            compliance with regulatory obligations such as the filing of returns
            and submission of information. Such returns or submissions shall
            only be filed and signed by the required officers of the Company.
          </li>
          <li>
            All other engagement with regulators should be approved by the
            Managing Director/CEO on advice from the Legal or Compliance Unit.
          </li>
        </ul>

        <strong>6.4 Management of confidential information</strong>
        <Text>
          The provisions of the Code of Conduct shall apply in matters relating
          to confidential information.
        </Text>

        <strong>6.5 Communication of non-public information</strong>
        <ul style={{ listStyle: "lower-roman" }}>
          <li>
            Communication of non-public information shall always be treated with
            extreme care. This is because any leak of non-public information to
            the public may expose Company to reputational risk.
          </li>
          <li>
            The following rules shall apply to communication of non-public
            information:
            <ul>
              <li>
                Only the Chairman or Managing Director/CEO is authorised to
                communicate non-public information to our stakeholders.
              </li>
              <li>
                Where in doubt of what constitutes non-public information,
                please check with the Head, MCC.
              </li>
              <li>
                Employees shall not provide non-public information to any source
                unless:
                <br></br>The employee is authorised by the Managing Director/CEO
                to do so; and
                <br></br>The employee is sure the information is accurate and
                authorised for release.
              </li>
            </ul>
          </li>
        </ul>

        <Heading>6.6 World Wide Web</Heading>
        <ul>
          <li style={{ listStyle: "lower-roman" }}>
            The standards for the Company web presence are created and managed
            by MCC.
          </li>
          <li>
            Other employees (outside the MCC team), agencies and other
            stakeholders are not authorised to place information of any kind
            regarding the Company or its operations or plans on any portion of
            the web.
          </li>
          <li>
            Individual employees of the Company are prohibited from using, or
            approving the use of the Company’s logos, copyrighted or proprietary
            materials of the Company on the web without the Managing
            Directors/CEO’s approval.
          </li>
          <li>
            All requests to register domain names on behalf of the Company
            anywhere in the world shall be coordinated by the MCC Department.
            This is to ensure consistency, total brand protection and
            functionality of our web presence.
          </li>
          <li>
            The use of any of the Company’s logo on a third-party website
            requires prior consent in writing from the Managing Director/CEO.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <Heading>7. Non-Compliance</Heading>
        <Text>
          Non-compliance with this Policy shall attract disciplinary actions in
          line with the provisions of the Disciplinary Process and Sanctions
          Grid Policy.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>8. Waivers</Heading>
        <Text>
          The Managing Director/CEO shall approve all requests for any waiver to
          this Policy. All such waiver approvals shall be obtained in writing
          and kept as a record by the Policy Owner.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>9. Delegation</Heading>
        <Text>
          Any delegation of authority conferred by this Policy shall be in
          accordance with the approved procedure for the delegation of authority
          as set out in the Delegation of Authority & Empowerment Policy.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>10. Review and Amendment</Heading>
        <Text>
          This Policy shall be reviewed every three years by the Policy Owner,
          and may be amended, subject to approval, if deemed necessary. The
          Company however reserves the right to change any of the provisions of
          this policy as it deems fit or required from time to time and such
          change shall apply to all Staff of the Company from the date of change
          as it relates to the subject-matter.
        </Text>
      </Paragraph>
    </ContentPage>
  );
};

export default Communication;
