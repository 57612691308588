import React from "react";
import { Row } from "antd";
import Plan from "./Plan";
import { BigText, PlansContainer } from "../style";
// import { ReactComponent as TargetIcon } from "../../../assets/images/target.svg";
import { ReactComponent as PaymentIcon } from "../../../assets/images/payment-method.svg";
// import { ReactComponent as ABCBlockIcon } from "../../../assets/images/abc-block.svg";
import { ReactComponent as ParentsIcon } from "../../../assets/images/parents.svg";
import { ReactComponent as SalaryPlusIcon } from "../../../assets/images/salary-plus.svg";
import { ReactComponent as TermLifeIcon } from "../../../assets/images/term-life.svg";
import { ReactComponent as MyHeirsIcon } from "../../../assets/images/my-heirs.svg";
import { ReactComponent as WholeLifeIcon } from "../../../assets/images/whole-life.svg";
import { ReactComponent as EntrepreneurIcon } from "../../../assets/images/entrepreneur.svg";
import { ReactComponent as SchoolPlanIcon } from "../../../assets/images/school-plan.svg";
// import { ReactComponent as LoanProtectIcon } from "../../../assets/images/loan-protect.svg";
import { ReactComponent as HeirsSaveIcon } from "../../../assets/images/Heirs_save.svg";
import { ReactComponent as GroupLifeIcon } from "../../../assets/images/group-life.svg";
import { ReactComponent as LoanIcon } from "../../../assets/images/loan.svg";
import { ReactComponent as MortgageIcon } from "../../../assets/images/mortgage.svg";
import { ReactComponent as KeymanIcon } from "../../../assets/images/keyman.svg";

const PlanItems = () => {
  const plans = [
    {
      title: "Triple Pay Investment",
      icon: PaymentIcon,
      price: "N5,000",
      textmsg:
        "The Heirs Triple Pay Investment plan is for smart people who want to save towards a long-term project while still earning during the period. You pick a target bulk amount you want to receive in future and start saving periodically towards it. Heirs Life pays you three bulk payments throughout your saving tenure, with high interests. For peace of mind, if the unfortunate happens to you while saving, your beneficiary still earns the complete 100% of the target amount plus bonus.",
      url: "/triple-pay",
      mixpanel: "Triple Pay Investment Bancassurance",
      quote:
        "https://partnerships.heirslifeassurance.com/savings-type?policy-type=Heirs%20Triple%20Pay%20Plan&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    {
      title: "Heirs Endowment Assurance",
      icon: PaymentIcon,
      price: "N3,000",
      textmsg:
        "With this plan you choose a target amount you want to earn at the end of your savings duration and pay an amount periodically, depending on your age. If anything happens to you, beneficiaries get the total target amount plus bonus (where applicable). If you survive the duration of the plan, you get the total target amount plus bonus.",
      url: "/product",
      mixpanel: "Endowment Assurance Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/savings-type?policy-type=Heirs%20Endowment%20Assurance&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    /* {
      title: "Couples Investment",
      icon: ParentsIcon,
      price: "N10,000",
      textmsg: "",
      url: "/couples",
      mixpanel: "Home Couples Plan Button",
      quote:
        "https://simple.heirslifeassurance.com/user?policy-type=Heirs%20Couple%20Plan",
    }, */
    {
      title: "Salary Plus",
      icon: SalaryPlusIcon,
      price: "N5,000",
      textmsg:
        "This plan is perfect for salary earners who want to earn competitive returns while saving towards future goals or projects. Automate the deduction of a steady amount from your salary which will earn interests over the desired period. This plan comes with a life insurance benefit.",
      url: "/salary",
      mixpanel: "Salary Plus Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/savings-type?policy-type=Heirs%20Salary%20plus&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    {
      title: "Heirs Term Assure",
      icon: TermLifeIcon,
      price: "N1,000",
      textmsg:
        "Heirs Term Assure: No one can predict tomorrow, but if your family depends on your income, then you need this plan so you can live confidently. The Term Life plan guarantees income replacement for your family if you are no more, so that your loved ones can carry on with life without hardship.",
      url: "/term-assure",
      mixpanel: "Term Assure Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/family-type?policy-type=Heirs%20Term%20Assure&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    {
      title: "MyHeirs Plan",
      icon: MyHeirsIcon,
      price: "N5,000",
      textmsg:
        "This is the perfect plan for parents who want to secure their child's future, especially for their education in the event the unfortunate happens. MyHeirs combines periodic savings and life insurance to cover the expenses of your child's future needs. You choose a target amount you want for your child and start saving an amount periodically, earning interest on it. If anything happens to you, your child gets the total target amount plus total savings plus interest. If you survive the duration of the plan, you get total savings plus interest.",
      url: "/my-heirs",
      mixpanel: "MyHeirs Plan Bancassurance",
      quote:
        "https://bancasurrance.heirslifeassurance.com/children-type?policy-type=MyHeirs%20Plan&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    {
      title: "MyHeirs Plus Plan",
      icon: MyHeirsIcon,
      price: "N5,000",
      textmsg:
        "This is the perfect plan for parents who want to secure their child's future, especially for their education in the event the unfortunate happens.MyHeirs Plus, you choose a target amount you want your child to inherit in the future and start paying an amount periodically, depending on your age. If anything happens to you, even on day 2 of the plan, your child gets the total target amount plus bonus. If you survive the duration of the plan, you get the total target amount plus bonus (where applicable).",
      url: "/my-heirs",
      mixpanel: "MyHeirs Plus Plan Bancassurance",
      quote:
        "https://bancaussurance.heirslifeassurance.com/children-type?policy-type=MyHeirs%20Plus%20Plan&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    /* {
      title: "Whole Life",
      icon: WholeLifeIcon,
      textmsg: "N5,000",
      url: "/whole-life",
      quote:
        "https://simple.heirslifeassurance.com/user?policy-type=Heirs%20Whole%20Life%20Assurance",
    }, */
    {
      title: "Heirs Entrepreneurs Plan",
      icon: EntrepreneurIcon,
      price: "N20,000",
      textmsg:
        "Running a successful business takes time, effort and money. The Heirs Entrepreneurs plan is perfect for small businessowners who want to save towards expanding their business and earn competitive interests on their savings. This plan comes with a life insurance benefit.",
      url: "/entrepreneur",
      mixpanel: "Entrepreneur Plan Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/business-type?policy-type=Heirs%20Entreprenuers%20Plan&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    /* {
      title: "Smart School Plan",
      icon: SchoolPlanIcon,
      textmsg: "N3,000",
      url: "/smart-school",
      quote: "",
    }, */
    {
      title: "Heirs Annuity Plan",
      icon: HeirsSaveIcon,
      price: "N3,000",
      textmsg:
        "The Heirs Life Pension-regulated Annuity plan is specially designed for retirees aged 50 and above who want to earn a steady, life-long income, after retirement.",
      url: "/product",
      mixpanel: "Annuity Plan Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/annuity-option?policy-type=Heirs%20Annuity%20Plan&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    {
      title: "Heirs Annuity Lite",
      icon: LoanIcon,
      price: "N3,000",
      textmsg:
        "This plan is a variant of the Heirs Annuity Plan designed for anyone from age 45 and above, who wants to receive a steady income for life.",
      url: "/product",
      mixpanel: "Annuity Lite Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/annuity-option?policy-type=Heirs%20Annuity%20Lite&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    {
      title: "Salary Plus Plan",
      icon: HeirsSaveIcon,
      price: "N5,000",
      textmsg:
        "This plan is perfect for salary earners who want to earn competitive returns while saving towards future goals or projects. Automate the deduction of a steady amount from your salary which will earn interests over the desired period. This plan comes with a life insurance benefit.",
      url: "/heirs-save",
      mixpanel: "Salary Plus Plan Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/savings-type?policy-type=Heirs%20Salary%20plus&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    {
      title: "Heirs Surgery Care plan",
      icon: EntrepreneurIcon,
      price: "N5,000",
      textmsg:
        "This plan helps you pay medical bills for illnesses requiring surgery such as cancer, heart failure, kidney failure, stroke, appendicitis, surgeries relating to accidents and more.",
      url: "/product",
      mixpanel: "Surgery Care Plan Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/health-type?policy-type=Heirs%20Surgery%20Care%20Plan&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
  ];
  const corporatePlans = [
    /* {
      title: "Group Life",
      icon: GroupLifeIcon,
      textmsg: "N3,000",
      url: "/group-life",
      mixpanel: "Home Group Life Button",
      quote: "",
    }, */
    {
      title: "Heirs Credit Life Assurance",
      icon: LoanIcon,
      price: "N5,000",
      textmsg:
        "The Heirs Credit Life plan is taken by lending organisations to provide financial security to institutions that lend money to people.",
      url: "/credit-life",
      mixpanel: "Credit Life Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/protection-type?policy-type=Heirs%20Credit%20Life%20Assurance&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    {
      title: "Heirs Mortgage Plan",
      icon: MortgageIcon,
      price: "N3,000",
      textmsg:
        "The Heirs Mortgage plan saves your family from the financial burden of paying an outstanding mortgage if the unfortunate happens to you. In such a case as this, Heirs Life will pay the outstanding mortgage to ensure that your family still acquires the home you worked hard for.",
      url: "/mortgage",
      mixpanel: "Mortgage Plan Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/protection-type?policy-type=Heirs%20Mortgage%20Protection%20Assurance&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
    {
      title: "Heirs Keyman Assurance",
      icon: KeymanIcon,
      price: "N3,000",
      textmsg:
        "This plan is usually taken by organizations or businesses to protect the life of a key staff or shareholder whose absence may cripple the business.",
      url: "/keyman-assurance",
      mixpanel: "Keyman Bancassurance",
      quote:
        "https://bancassurance.heirslifeassurance.com/business-type?policy-type=Heirs%20Keyman%20Assurance&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
    },
  ];

  return (
    <PlansContainer>
      <BigText>For Individuals</BigText>
      <Row gutter={[16, 0]} align={"center"}>
        {plans.map(
          ({ title, price, textmsg, icon, url, mixpanel, quote }, key) => (
            <Plan
              mixpanel={mixpanel}
              title={title}
              textmsg={textmsg}
              icon={icon}
              url={url}
              key={key}
              quote={quote}
              price={price}
            />
          )
        )}
      </Row>

      <BigText>For Corporate</BigText>
      <Row gutter={[16, 0]} align={"center"}>
        {corporatePlans.map(
          ({ title, price, textmsg, icon, url, mixpanel, quote }, key) => (
            <Plan
              mixpanel={mixpanel}
              title={title}
              textmsg={textmsg}
              icon={icon}
              url={url}
              key={key}
              quote={quote}
              price={price}
            />
          )
        )}
      </Row>
    </PlansContainer>
  );
};

export default PlanItems;
