import React from "react";
import {
  Wrapper,
  Title,
  BannerContentWrapper,
  Content,
  Text,
} from "./bannerStyle";
import Icon from "@ant-design/icons";
import { ReactComponent as LocationIcon } from "../../assets/images/location.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/email.svg";
const Banner = ({ title, backgroundImage, children }) => {
  return (
    <Content backgroundImage={backgroundImage}>
      <Wrapper>
        <BannerContentWrapper>
          <Title> {title}</Title>
          {/* <Text>
            <h3>Branch Locations:</h3>
            <br />
          </Text> */}
          {/* <Text>1. 107b Ajose Adeogun street, Victoria Island, Lagos.</Text> */}
          {/* <Text>2. 4, Julius Nyerere Crescent, Asokoro Abuja, Nigeria.</Text> */}
          <br />
          <Text>
            <Icon component={LocationIcon} /> Plot 107B Ajose Adeogun Street,
            Victoria Island, Lagos.
          </Text>
          <Text>
            <Icon component={PhoneIcon} /> 0700 434 7754
          </Text>
          <Text>
            <Icon component={EmailIcon} /> wecare@heirslifeassurance.com
          </Text>
        </BannerContentWrapper>
      </Wrapper>
    </Content>
  );
};

export default Banner;
