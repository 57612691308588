import React from "react";
import Banner from "../../components/ConnectBanner";
import Partnership from "../../components/Partnership";
import Inspiration from "../../components/Partnership/Inspiration";
import Benefits from "../../components/Partnership/Benefits";
import Connect from "../../components/Partnership/Connect";
import Faqs from "../../components/Partnership/Faqs";
// import backgroundImage from "../../assets/images/tenant.png";
import backgroundImage from "../../assets/images/InConn.png";

const Index = () => {
  const bannerPayload = {
    title: "InConnect",
    descriptions: [
      "Do more with Insurance.",
      "InConnect is a digital platform by Heirs Life that allows businesses to refer insurance and grow their revenue, while providing more value to their customers.",
    ],
    backgroundImage,
    linkText: "Sign Up",
    // linkPath: "https://partners-app.staging.heirslifeassurance.com/signup",
    // link_Path: "https://partners-app.staging.heirslifeassurance.com/signin",
    linkPath: "https://partners.heirslifeassurance.com/signup",
    link_Path: "https://partners.heirslifeassurance.com/signin",
    link_Text: "Sign In",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      {/* <Partnership /> */}
      <Benefits />
      <Connect />
      <Faqs />
      <Inspiration />
    </div>
  );
};

export default Index;
