import React from "react";
import { Row, Col } from "antd";
import { Wrapper, Content, ContentItem, Title, Text } from "./styles";
import Section from "../Section";
import tony from "../../assets/images/Tony.png";
import niyi from "../../assets/images/niyi.png";
import chiugo from "../../assets/images/chiugo.png";
import adesombi from "../../assets/images/Adesimbo.png";
import dan from "../../assets/images/Dan.png";
import emmanuel from "../../assets/images/Emmanuel.png";
import maryam from "../../assets/images/Maryam.png";
import tosin from "../../assets/images/Tosin.png";
import suleyman from "../../assets/images/Suleyman.png";
import mohammed from "../../assets/images/Mohammed.png";

const Index = () => {
  return (
    <Wrapper>
      <Content>
        <Section title="Leadership" filled>
          <Row gutter={50}>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={tony} alt="leader" />
                <Title> Tony O. Elumelu CFR</Title>
                <Text> Chairman</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={dan} alt="leader" />
                <Title> Dan Okeke</Title>
                <Text> Vice Chairman</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={niyi} alt="leader" />
                <Title> Niyi Onifade</Title>
                <Text> Managing Director/CEO</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={tosin} alt="leader" />
                <Title> Tosin Bayo-Yusuf</Title>
                <Text> Executive Director/COO</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={maryam} alt="leader" />
                <Title> Maryam Sani</Title>
                <Text> Independent Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={emmanuel} alt="leader" />
                <Title> Emmanuel Nnorom</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={chiugo} alt="leader" />
                <Title> Chiugo Ndubisi</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={adesombi} alt="leader" />
                <Title> Adesimbo Ukiri</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={suleyman} alt="leader" />
                <Title> Suleyman Ndanusa</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={mohammed} alt="leader" />
                <Title> Mohammed Bello</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
          </Row>
        </Section>
      </Content>
    </Wrapper>
  );
};

export default Index;
