import styled, { css } from "styled-components";
import * as Colors from "../../utils/colors";
import {
  containerWrapper,
  otherPrimaryBoxShadowWt5,
  primaryBoxShadow,
  primaryBoxShadowWt5,
} from "../../utils/stylescheet";
import { TEXT_DEFAULT } from "../../utils/colors";

export const Title = styled("h1")`
  font-size: 20px;
  line-height: 18px;

  span {
    color: ${Colors.PRIMARY};
  }

  @media screen and (min-width: 992px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 10px;
  }
`;

export const SubTitle = styled("h2")`
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: ${Colors.GRAY_65};
`;

export const Text = styled("p")`
  font-size: 15px;

  span {
    color: ${Colors.PRIMARY};
  }
`;

export const StyledLink = styled("a")`
  background: ${Colors.PRIMARY};
  color: ${Colors.WHITE};
  padding: 15px;
  font-family: "Paralucent", sans-serif;

  &:hover {
    color: ${Colors.WHITE};
    background: ${Colors.BLACK};
  }
`;

export const IconWrapper = styled("div")`
  text-align: center;
  margin-bottom: 7px;

  .anticon {
    display: block;
  }

  img {
    width: 100%;
  }
`;

export const MobileLinkWrapper = styled("div")`
  display: block;

  @media screen and (min-width: 992px) {
    display: none;
  }

  a {
    @media screen and (max-width: 575px) {
      display: inline-block;
    }
  }
`;

export const LinkWrapper = styled("div")`
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }

  a {
    @media screen and (max-width: 575px) {
      display: inline-block;
    }
  }
`;

export const PlanItemContent = styled("div")`
  padding: 0.8em 0.5em 0;
  text-align: center;

  a {
    font-size: 0.95em;
    line-height: 15px;
    color: ${Colors.TEXT_DEFAULT};

    &:hover {
      color: ${Colors.PRIMARY};
    }
  }
`;

const flippedPlan = css`
  background: ${Colors.WHITE};
  ${primaryBoxShadowWt5}

  ${Text} {
    color: ${Colors.PRIMARY};
  }
`;

const otherFlippedPlan = css`
  background: ${Colors.WHITE};
  ${otherPrimaryBoxShadowWt5}

  ${Text} {
    color: ${Colors.PRIMARY};
  }
`;

export const PlanItem = styled("div")`
  position: relative;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.8s ease-in-out;
  background: ${Colors.WHITE};

  ${Text} {
    font-size: 0.95em;
    line-height: 15px;
    text-align: center;
    margin: 7px 0 !important;
    text-transform: capitalize;
    font-weight: bold;
  }

  .anticon {
    svg {
      height: 60px;
      width: auto;
      fill: ${Colors.PRIMARY};

      @media screen and (max-width: 576px) {
        height: 30px;
      }
    }
  }

  &:hover {
    background: ${Colors.WHITE};
    ${primaryBoxShadowWt5}

    ${Text} {
      color: ${Colors.PRIMARY};
    }
  }

  ${({ id, flip }) =>
    id === "content" ? flip && otherFlippedPlan : flip && flippedPlan};
`;

export const PlanItems = styled("div")`
  margin-bottom: 20px;

  .slick-prev {
    left: -14px;
  }

  .slick-next {
    right: -14px;
  }

  .slick-next,
  .slick-prev {
    svg {
      height: 1rem;
      width: 1rem;
      color: ${Colors.BLACK};
    }
  }

  .slick-initialized .slick-slide {
    padding: 5px;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 45px;
  }

  @media screen and (max-width: 1024px) and (min-width: 420px) {
    width: 70% !important;
    margin: 0 auto 20px auto;
  }

  @media screen and (max-width: 768px) {
    .ant-row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

export const ImageWrapper = styled("div")`
  text-align: center;
  padding: 15px 0;
  color: ${TEXT_DEFAULT};

  @media screen and (min-width: 992px) {
    position: fixed;
    bottom: 5px;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100px;
    height: auto;
    margin: 0 10px;
  }
`;

export const ItemWrapper = styled("div")`
  img {
    width: 100%;
  }

  ${Text} {
    margin: 25px 0;
    text-align: center;
  }
`;

export const PlayWrapper = styled("div")`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .anticon {
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    width: 45px;
    height: auto;
    fill: ${Colors.PRIMARY};
  }

  ${primaryBoxShadow}
`;

export const BackgroundWrapper = styled("div")`
  position: absolute;
  top: 0;

  ${IconWrapper} {
    // margin-top: -500px; //remove after proper image is given
    opacity: 0.5;
  }
`;

export const BannerContentWrapper = styled("div")`
  position: relative;
  width: 930px;
  height: 930px;
  border-radius: 50%;
  border: solid 1px ${Colors.DARK};
  margin: 50px auto 0;

  ${Title} {
    margin: 100px auto 0;
  }

  ${SubTitle} {
    margin: 20px auto 0;
  }
`;

export const Wrapper = styled("div")`
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, #f7f7f7 50%, rgba(255, 249, 249, 1) 50%);

  @media (max-width: 991px) {
    background: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    width: 100%;
  }
`;

export const ContentWrapper = styled("div")`
  ${containerWrapper}
`;

export const Content = styled("div")`
  height: calc(100vh - 75px);
  background-color:  #f7f7f7;
  display: flex;
  align-items: center;
  padding-right: 100px;

  div {
    width: 100%;
  }

  ${PlanItem} {
    &:hover {
      background: ${Colors.WHITE};
      ${otherPrimaryBoxShadowWt5}

      ${Text} {
        color: ${Colors.PRIMARY};
      }
    }
  }


  @media screen and (max-width: 1024px) {
    height: auto;
    text-align: center;
    background-color: inherit;
    padding-right: 0;
    margin-bottom: 60px;
  }

  @media screen and (max-width: 991px) {
    padding-right: inherit;
    margin-bottom: 0;
  }
  }

  ${Title} {
    @media screen and (max-width: 991px) {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 991px) {
    padding: 25px 0;
  }
`;

export const RightContent = styled(Content)`
  background: transparent;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 992px) {
    padding-left: 100px;
  }
`;

export const DownloadItemContent = styled("div")`
  display: inline-block;
  vertical-align: middle;

  ${SubTitle} {
    font-size: 12px;
    text-align: left;
  }

  svg {
    width: 30px;
    height: auto;
    fill: ${Colors.GRAY_65};
    margin-right: 10px;
  }
`;

export const DownloadItemWrapper = styled("div")`
  padding: 15px 15px 10px;
  border-radius: 45px;
  border: solid 1px ${Colors.GRAY_65};
  display: inline-block;
  margin-right: 15px;

  ${Title} {
    text-transform: capitalize !important;
    line-height: 6px !important;
  }

  ${SubTitle} {
    line-height: 6px;
  }
`;

export const DownloadWrapper = styled("div")`
  position: fixed;
  bottom: 40px;
  right: 100px;

  ${Title} {
    font-size: 15px;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;
    color: ${Colors.GRAY_65};
  }
`;
