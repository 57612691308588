import styled from "styled-components";
import * as Colors from "../../utils/colors";
// import { containerWrapper } from "../../utils/stylescheet";

export const Text = styled("p")`
  color: ${Colors.TEXT_DEFAULT};
  font-size: 20px;
  margin-bottom: 20px;

  ul {
    list-style: disc;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ClaimText = styled("p")`
  color: ${Colors.TEXT_DEFAULT};
  font-size: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 991px) {
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  color: ${Colors.TEXT_DEFAULT};
  margin-bottom: 6px;

  @media screen and (max-width: 991px) {
    font-size: 35px;
    line-height: 35px;
  }

  @media screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 30px;
  }

  @media screen and (max-width: 575px) {
    font-size: 20px;
    line-height: 15px;
  }
`;

export const Overlay = styled("div")`
  margin-top: 4.4%;
  background-color: rgb(243 84 91 / 7%);
  padding: 20px 0;
`;

export const Wrapper = styled("div")`
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin: 0 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    padding-right: 0;
    padding-left: 27.5px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }

  @media (min-width: 1600px) {
    width: 1450px;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    div {
      margin: 20px 0;
    }
  }
`;

export const Content = styled("div")`
  min-height: 500px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: no-repeat;
  background-size: 500px;
  background-position: right top;
  width: 100%;
  @media screen and (max-width: 1024px) {
    background-position: 150% top;
  }
  @media screen and (max-width: 480px) {
    background-image: none;
  }
`;

export const BannerContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 550px;
  background-size: contain;
  width: 100%;

  @media screen and (max-width: 991px) {
    min-height: 400px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    min-height: 250px;
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    min-height: 450px;
    width: 50%;
  }

  @media screen and (min-width: 1200px) {
    min-height: 450px;
    width: 50%;
  }
`;

export const LinkWrapper = styled("div")`
  margin-top: 15px;
  display: inline-block;

  a {
    padding: 10px 20px;
    background: ${Colors.PRIMARY};
    color: ${Colors.WHITE};
    display: inline-block;
    align-items: center;
    font-weight: bold;
    margin-right: 20px;
    border-radius: 5px;

    svg {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }

    &:hover {
      color: ${Colors.WHITE};
      background: ${Colors.BLACK};
    }
  }
`;

export const Video = styled("div")`
  width: 50%;

  @media (max-width: 978px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;
