import React, { useState } from "react";
import FacebookIcon from "../../assets/images/heirs-icons-facebook.svg";
import InstagramIcon from "../../assets/images/heirs-icons-instagram.svg";
import YoutubeIcon from "../../assets/images/heirs-icons-youtube.svg";
import LinkedInIcon from "../../assets/images/heirs-icons-linkedin.svg";
import { SocialMediaWrapper } from "./style";
import { Mixpanel } from "../../Mixpanel";
import { Row, Col } from "antd";
import {
  Wrapper,
  ItemWrapper,
  Title,
  ListWrapper,
  ListItemWrapper,
  Text,
  List,
  Video,
} from "./style";
import Lottie from "lottie-react-web";
import animationData from "../../assets/animations/about-us.json";
const defaultOptions = {
  loop: true,
  animationData,
};
const Index = () => {
  const [activeTab, setActiveTab] = useState(0);

  const bold = {
    backgroundColor: "red",
    padding: "10px 20px",
    color: "white",
    borderRadius: "10px",
    overflow: "hidden",
  };
  const itlics = {
    fontWeight: "bold",
    fontSize: "12pt",
  };
  const itlicx = {
    fontSize: "13pt",
  };
  const pstyle = {
    textAlign: "center",
    margin: "0 auto",
    fontSize: "13pt",
  };
  const xstyle = {
    margin: "20px auto",
  };

  return (
    <Wrapper>
      <Row gutter={[16, 0]}>
        <Col lg={10}>
          <img
            src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/09/essay-winner-2023.jpg"
            width="100%"
            options={defaultOptions}
          />
        </Col>
        <Col lg={13} offset={1}>
          <ListWrapper>
            <ListItemWrapper
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              The Help We Wish We All Had
            </ListItemWrapper>
          </ListWrapper>

          <ItemWrapper active={activeTab === 0}>
            <Text>
              It's your child's graduation ceremony. The young chap has been
              resilient and hardworking. You couldn't be prouder. You take a few
              cute pictures, say hi to his (or her) friends, laugh warmly, and
              wave heartily at other parents. Then you pat him on the shoulder,
              look him straight in the eye, and gave the talk…
            </Text>
            <Text>
              “I am proud of you,” you say. “You've done very well, and I can't
              wait to see what the next level holds.”
            </Text>
            <Text>
              Then you reach into your breast pocket or your purse, and bring
              out a neat envelope: “This is for you,” you continue. “Contained
              within is an account that has 10 million naira for whatever you
              want to do next.”
            </Text>
            <Text>
              <b>How do you think your child will feel? </b>
            </Text>
            <Text>
              <b>What kind of leverage would he have towards his goals? </b>
            </Text>
            <Text>
              This may look like a far-fetched dream, but it is not. This can be
              easily achieved with the MyHeirs plan from Heirs Insurance Group
              and we have simplified the whole process for you!
            </Text>
            <Text>
              But first, if you are new to an insurance savings, you need to
              understand why it is one of the smartest tools used for wealth
              creation and management.
            </Text>
          </ItemWrapper>
        </Col>
        <Col lg={11} offset={1}>
          <Text>
            <b>Saving With an Insurance Company</b>
          </Text>
          <Text>
            Unlike saving in a bank, an insurance company offers you the
            opportunity to save and get an additional bulk sum of money paid to
            your family if the unfortunate happens to you during the savings
            period. This is known as a life insurance benefit.
          </Text>
          <Text>
            <b>Now, the Magic of Our MyHeirs Plus Plan</b>
          </Text>
          <Text>
            This is what we call an endowment/inheritance type of plan. A lot of
            parents go for this one because it promises to pay an agreed amount
            (agreed between you and us) to cater to your children's future
            education whether you are here or not. The amount you secure can be
            N1million, N10million, etc - depending on you. And what you pay
            monthly to guarantee this benefit is based on your age.
          </Text>
        </Col>
        <Col lg={11} offset={1}>
          <Text>
            <b>Now the really amazing thing about this plan is that:</b>
          </Text>
          <Text>
            If the worst were to happen, even on Day 2 of your plan, Heirs Life
            will pay the full agreed amount as an inheritance to your children
            to continue their education. No questions asked.
          </Text>
          <Text>
            And if nothing happens and you outlive the duration of the policy,
            you get the full agreed amount at the end of the tenure. This is
            perfect if you have kids, and the minimum savings duration is 5
            years (as this is aimed towards your child's future). But you can
            speak to us if you want a shorter savings duration.
          </Text>
        </Col>
        <Col
          lg={22}
          offset={1}
          style={{
            textAlign: "center",
          }}
        >
          <Text
            style={{
              color: "#ff0000",
              fontWeight: "bold",
            }}
          >
            Do you have an amount you want to give your child in future?
          </Text>
          <Text style={{ display: "block", margin: "0px auto" }}>
            Fill the interest form below and we will tell you what to save
            monthly to secure it.
          </Text>
          <Text
            style={{
              color: "#ff0000",
              fontWeight: "bold",
            }}
          >
            Decided on a monthly amount you want to save? (e.g N10,000 per
            month)?
          </Text>
          <Text style={{ display: "block", margin: "0px auto" }}>
            Fill the interest form below and we will tell you how much
            inheritance you can secure for your child.
          </Text>
          <Text>
            <p>
              For updates on the Heirs Life Essay Championship follow us on
              social media
            </p>
            <SocialMediaWrapper
              style={{ display: "block", margin: "0px auto" }}
            >
              <a
                href="https://www.facebook.com/heirsinsurancegrou/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FacebookIcon} alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/heirsinsurancegroup/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstagramIcon} alt="instagram" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ"
                target="_blank"
                rel="noreferrer"
              >
                <img src={YoutubeIcon} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/heirsinsurancegroup/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedInIcon} alt="linkedin" />
              </a>
            </SocialMediaWrapper>
            <br />
            <div style={itlics}>
              <a href="https://api.whatsapp.com/send?phone=2349122222200">
                For inquires, chat with us on Whatsapp: 09122222200
              </a>
            </div>
            <br />
            <div>
              <a href="https://www.heirslifeassurance.com/blog">
                Click here for FAQs
              </a>
            </div>
          </Text>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Index;
