import React, { useEffect } from "react";
import { Text, Title, Wrapper } from "../PlanPicker/styles";
import hat from "../../assets/images/termassure.png";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constant";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  return (
    <Wrapper id="life">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <td className="ant-table-cell">
                  <Text>Financial Reports</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Annual Reports</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>General Meetings</Text>
                </td>
                <td className="ant-table-cell">
                  <Text> Investor Relations</Text>
                </td>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text></Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    <a
                      href={
                        "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EV8cYgEqcpFDr_dh3Ktw3-IBp8TMHWTpL6sKf9CvnQW4vA?e=g1zmJ9"
                      }
                    >
                      Click here{" "}
                    </a>{" "}
                    to read details of 13 Months Financial Statement ending Dec.
                    2021
                  </Text>
                </td>

                <td className="ant-table-cell">
                  <Text>
                    <a
                      href={
                        "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EUBIV3VUCZlMm55vzyeauCEBrd_qQjmHgmnnkK57CgBgAQ?e=J6uQ2t"
                      }
                    >
                      Click here{" "}
                    </a>{" "}
                    to read full details of the Notice of Annual General Meeting
                  </Text>
                </td>
                <td>
                  <Text>
                    <a
                      href={
                        "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/ESWuOCXblU5KtNJ2Atr-RzIBdsYshvF8TUO_HZ63-15GZg?e=gKpgAc"
                      }
                    >
                      Click here{" "}
                    </a>{" "}
                    to read full details of the Investor Relations Evaluation
                    Report
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell"></td>
                <td className="ant-table-cell"></td>
                <td className="ant-table-cell">
                  <Text>
                    {/* <a
                      href={
                        "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EaRwIwZx3ZpFj8EP8dXdit4B9furuXPx2zRtn2GqN5lw7w?e=KLRKDT"
                      }
                    >
                      Click here{" "}
                    </a>{" "}
                    to view a sample of the proxy form for the Annual General
                    Meeting */}
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    <a
                      href={
                        "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/EbnEboMVditAq-h1fv0h5lEBpy67OzE4Sq_b4pXkQM-ZMA?e=HWqIxX"
                      }
                    >
                      Click here{" "}
                    </a>{" "}
                    to read full details of the 2021 Board Evaluation Report
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default Index;
