import React from "react";
import Banner from "../../components/ProductBanner";
import BancassuranceProducts from "../../components/BancassuranceProducts";
import Benefits from "../../components/Bancassurance/Benefits";
import TrippleAssurance from "../../components/Bancassurance/TrippleAssurance";
import backgroundImage from "../../assets/images/bancassurance.png";
// import backgroundImage from "../../assets/images/partnership-banner.png";

const Index = () => {
  const bannerPayload = {
    title: <span>Heirs Life and UBA Bancassurance Partnership</span>,
    descriptions: [],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    // linkPath: "https://partners.heirsinsurance.com/",
    // linkText: "Watch how it works",
    // linkText: "Partnership Portal",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <BancassuranceProducts />

      {/*  <Benefits />

      <TrippleAssurance /> */}
    </div>
  );
};

export default Index;
