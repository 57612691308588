import HeirsLaunch from "../../assets/images/heirs-launch.jpeg";
import HeirsTowers from "../../assets/images/heirs-towers.png";
import HeirsMagazine from "../../assets/images/Heirs-Magazine.jpg";

export const data = [
  {
    id: "1",
    image: HeirsLaunch,
    title:
      "Heirs Holdings Enters Insurance Market - Promises Insurance Revolution",
    subtitle:
      "Heirs Insurance and Heirs Life Offer Simple, Quick, Accessible and Reliable Insurance to Nigerians",
    imageDescription:
      "l-r:  Ag. Managing Director/CEO, Heirs Insurance Limited, Dr. Adaobi Nwakuche;  Commissioner for Insurance/Chief Executive Officer, National Insurance Commission (NAICOM),  Mr. Sunday Thomas; Executive Governor, Lagos State, Mr. Babajide Sanwo-Olu; Chairman, Heirs Holdings and Heirs Insurance Limited, Mr. Tony Elumelu; Managing Director/CEO, Heirs Life Assurance Limited, Mr. Niyi Onifade; and Executive Director, Heirs Holdings and Chairman, Heirs Life Assurance Limited; at the official launch of Heirs Insurance Limited and Heirs Life Assurance Limited and the commissioning of Heirs Towers in Victoria Island, Lagos on Tuesday.",
    textStart: "June 1, 2020, Lagos, Nigeria:",
    firstText:
      "Heirs Holdings (HH) announced the Group’s transformative insurance industry play, with the launch of Heirs Insurance Limited (HIL) and Heirs Life Assurance (HLA), on Tuesday, June 1, 2021, at the companies’ Victoria Island headquarters in Lagos.  Heirs Holdings will bring a dynamic, digitally driven offering to the general and life insurance sectors, providing clients with a real choice of products, pricing, channel, and claims handing.",
    content: [
      "Heirs Insurance Limited (HIL) provides general insurance, offering policies covering vehicles, homes, SMEs, business risks, and other properties.  Heirs Life Assurance (HLA) offers insurance-backed savings plans and life insurance security for individuals, families, children’s education, debtors, creditors, entrepreneurs, and employees. All plans offer customers comprehensive protection, creating financial certainty for loved ones, cherished assets, and properties",
      "Both companies leverage the business track record of Heirs Holdings, the pan-African investment group, chaired by leading investor and philanthropist, Tony O. Elumelu, CON.  Heirs Holdings’ portfolio includes companies transforming financial services, power, oil and gas, real estate, hospitality, healthcare, and technology.",
      "At the launch, the companies announced a series of product innovations, including a web portal enabling customers to get an insurance quote in minutes and receive claims settlement in less than 24 hours, from their mobile devices.  The highly intuitive and easy to use portal assists the total customer journey, from comparing products to finding the right cover.  They also unveiled a suite of customer friendly, affordable products, that are simple to understand, featuring flat pricing and tailored directly to the Nigerian market and the needs of Nigerians.",
      "Heirs Insurance, the general insurer, with its mandate to enable Nigerians to protect their valuables and property, unveiled flexible low-cost comprehensive motor insurance and new insurance products targeting users including bikers, tenants, and SMEs. Heirs Life unveiled affordable savings, term life and inheritance plans, that provide financial security throughout customers’ life journey.",
      "Dr. Adaobi Nwakuche, Ag. MD/CEO, Heirs Insurance, said: “We are delivering insurance that is easy to understand, quick and reliable, simplifying our customers’ lives.  Our mission is simple: make insurance accessible.  Our goal is to transform customer service: cutting waiting time in claims processing and purchase to bare minutes — across all touchpoints, including mobile devices.  At Heirs Insurance, we want to give our customers what they have so often lacked – high quality insurance, at an affordable price – and with an insurer that is on their side”.",
      "Niyi Onifade, MD/CEO, Heirs Life, said: “Our goal is to integrate life insurance products into people’s daily lives to create financial inclusion and bring quality financial services to the many, not just the few.  This way, we empower people to live more confidently and achieve their dreams, whilst securing the future of their loved ones.  Heirs is all about giving our clients financial security.  As people build wealth, we want them to know that they have a partner in Heirs Life, providing financial support throughout their life”.",
    ],
  },
  {
    id: "2",
    image: HeirsTowers,
    title: "Governor Commissions Heirs Insurance Headquarters - Heirs Towers",
    subtitle:
      "Governor Sanwo-Olu, Nigeria’s Commissioner for Insurance, Commend Heirs Holdings’ Vision of Democratising Insurance with Launch of Insurance Business",
    textStart: "June 1, 2021, Lagos, Nigeria:",
    firstText:
      "At the launch of Heirs Holdings’ new insurance businesses – Heirs Insurance and Heirs Life Assurance - Lagos State Governor, Babajide Sanwo-Olu commended the pan-African investment company on its bold vision to democratise access to insurance.  The event formally showcased the new companies and their commitment to service excellence, and their mission to offer insurance that is simple, quick, reliable, and accessible to everyone.",
    content: [
      "The event also marked the commissioning of Heirs Towers, the 7-storey headquarters of Heirs Holdings, an impressive structure developed by Afriland Properties Plc, one of the investee companies of the Heirs Holdings group.  The building was commissioned by H.E. Babajide Sanwo-Olu, Lagos State Governor, with Mr. Sunday Olorundare Thomas, Commissioner for Insurance, in attendance.",
      "The Chairman of Heirs Holdings, Tony O. Elumelu, introduced the leadership of both companies and their distinguished Board of Directors, all seasoned individuals with decades of Nigerian and international experience. The CEOs, Dr. Adaobi Nwakuche, Ag. MD/CEO, Heirs Insurance Limited and Niyi Onifade, MD/CEO, Heirs Life Assurance Limited, both have decades of experience in the insurance industry.",
      "Heirs Insurance and Heirs Life have a paid-up share capital of N10billion and N8billion respectively, and are backed by leading reinsurers, providing a second layer of security for clients' insurance portfolios.",
      "Speaking, Tony Elumelu, Chairman of Heirs Holdings Group confirmed at the launch, the readiness of both companies to usher in a new era in the insurance industry.  He stressed that both companies demonstrate the vision of Heirs Holdings to position the private sector as a key enabler of economic and social wealth creation in Africa.",
      "“Heirs Insurance and Heirs Life once again demonstrate our core purpose of improving lives and transforming Africa.  In our years of operations.  Across different industries and countries, we have become synonymous with customer focus and the highest levels of investor relations. These are the hallmarks for these new businesses.  We know that our promise, our people and our proven track record will transform the insurance industry, put the customer first and empower people, in good and bad times”.",
      "H.E. Babajide Sanwo-Olu, the Governor of Lagos State said: “Heirs Insurance and Heirs Life are bringing insurance that is simple, accessible, and affordable - three strong words that businesses of today need to thrive in highly saturated markets. I am aware that both Heirs Life and Heirs Insurance are two of the most liquid and well-capitalized insurance companies in the country right now. They have shown us that it is freshness they are bringing into the sector. It is a new beginning for the sector”.",
      "He added: “Insurance to GDP is still relatively low in the country. We need to change the narrative and I am sure that Heirs Insurance and Heirs Life can change that. Looking at the people sitting on the board it is clear to me that you will bring your world of experience that is required to take this sector to greater heights”.",
      "Mr. Olorundare Sunday Thomas, National Commission for Insurance said: 'The timing of this launch could not have been more auspicious as the insurance commission is currently rolling out different initiatives for National development. To Heirs Insurance and Heirs Life, I want to say thank you for coming into our industry, making a difference, impacting the economy, and helping us to deepen the insurance sector in Nigeria.'",
      "The event was attended by high profile guests including Kennedy Uzoka, Group Managing Director, United Bank for Africa Plc (UBA), top executives of banks, consulting firms, multinationals, and others.",
    ],
  },
  {
    id: "3",
    image: HeirsMagazine,
    title: "Simple Magazine - Maiden Edition",
    subtitle: "",
    textStart: "",
    firstText: "",
    content: [
      `<iframe src="https://online.flippingbook.com/view/115330754/" class="fbo-embed" data-fbo-id="115330754" data-fbo-lightbox="yes" data-fbo-width="740px" data-fbo-height="480px" data-fbo-version="1" style="max-width: 100%">Simple Magazine - Volume 1</iframe><script async defer src="https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=115330754"></script>`,
    ],
  },
];
