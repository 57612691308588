import React from "react";
import backgroundImage from "../../assets/images/group-banner.png";
import Banner from "../../components/ProductBanner";
import GroupLifePlanPicker from "../../components/GroupLifePlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Group Life",
    descriptions: [
      "The Heirs Group Life plan comes in two variants:",
      "<p><b>1) The Statutory Group Life Scheme</b></p>",
      "<p>This plan is perfect for employers of labor.</p>",
      "<p>According to the Pension Act 2014, if an employee dies in service, the employer must pay 3 times the employee’s annual salary to their stated beneficiary.</p>",
      "<p>The Statutory Heirs Group Life plan helps employers pay this compulsory bulk amount to the families of their staff, if the unfortunate happens.</p>",
      "<p><b>2) The Non-Statutory Group Life Scheme</b></p>",
      "<p>This plan is perfect for non-official group organisations such as clubs, associations, societies, etc.</p>",
      "<p>If a member of the group dies unexpectedly, Heirs Life will pay a bulk amount to the beneficiary of that member.</p>",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#group-life",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <GroupLifePlanPicker />
    </div>
  );
};

export default Index;
