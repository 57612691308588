import React from "react";
import Banner from "./Banner";
import backgroundImage from "../../assets/images/get-answers.png";
import { Wrapper } from "./styles";
import Media from "./Media";

const Index = () => {
  const bannerPayload = {
    title: "Media",
    backgroundImage,
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />

      <Media />
    </Wrapper>
  );
};

export default Index;
