import styled from "styled-components";
import * as Colors from "../../../utils/colors";
import { Layout } from "antd";

export const Wrapper = styled(Layout)`
  background: ${Colors.WHITE};
`;

export const FloatingIcon = styled("a")`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
`;

export const Content = styled(Layout.Content)`
  background: ${Colors.WHITE};
  padding: 0;
  margin-top: 70px;

  @media (max-width: 991px) {
    margin-top: 50px;
  }

  @media (min-width: 992px) {
    margin-top: 73px;
  }
`;

export const ProductPurchase = styled("span")`
  font-family: "Mulish Bold", sans-serif;
  font-weight: bold;
`;

export const CookieWrapper = styled("div")`
  position: fixed;
  bottom: 0;
  z-index: 2147483699;
  background: ${Colors.BLACK};
  width: 100%;

  @media (max-width: 767px) {
    padding: 40px 15px;
  }
`;

export const CookieContainer = styled("div")`
  @media (min-width: 768px) {
    width: 750px;
    margin: auto;
    padding: 15px;
    padding: 40px 15px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
`;
export const CookieText = styled("div")`
  border: none;
  display: flex;
  margin-top: 12px;

  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

export const CookieHeader = styled("h2")`
  color: ${Colors.WHITE};
`;

export const CookieContent = styled("p")`
  color: ${Colors.WHITE};

  a {
    color: #ffbdc3;
    border-bottom: 1px dotted;
  }
`;

export const CookieActions = styled("div")`
  display: flex;
  button.ant-btn {
    background: #fff;
    border: none;
    border-radius: 0;
    color: #000;
    width: 200px;
    height: 40px;
    margin-left: 20px;
  }

  @media (max-width: 1020px) {
    button.ant-btn {
      margin-top: 10px;
      margin: 15px 20px 0 0;
    }
  }
`;
