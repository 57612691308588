/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useEffect } from "react";
import { Row, Col, Modal } from "antd";
import { FormWrapper } from "./style";
import { Mixpanel } from "./../../Mixpanel";
import FormInput from "../../components/Input";
import axios from "axios";
import { HeirsEmailUrl } from "../../utils/constant";
import { useHistory } from "react-router-dom";

const FreeGift = ({ visible }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    plan: "",
    message: "Free Gift Claim",
    destination: ["wecare@heirslifeassurance.com"],
  });
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const history = useHistory();
  const handleGift = () => {
    // const data = {
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   phoneNumber: "",
    //   destination: [
    //     "wecare@heirslifeassurance.com",
    //     "nimiweremi.selekere@heirslifeassurance.com",
    //   ],
    //   message: "Free Gift",
    //   plan: "",
    // };
    const data = { ...value };
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: `${HeirsEmailUrl}/email/others`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          handleServerResponse(
            true,
            "Done! A voucher will be sent to you once you complete your purchase."
          );
        } else {
          handleServerResponse(false, response.data.message);
        }
      })
      .catch((error) => {
        handleServerResponse(false, "An internal error occured");
      });
  };

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
      Array.from(document.querySelectorAll("textarea")).forEach(
        (textarea) => (textarea.value = "")
      );
      setIsDisabled(false);
      setModalVisible(false);
      Modal.success({
        content: msg,
        onOk() {
          let last_accessed_product = localStorage.getItem(
            "last_accessed_product"
          );
          localStorage.setItem("last_accessed_product", "");
          localStorage.setItem("attempted_purchase", "");
          localStorage.setItem("free_gift_filled", "true");
          if (last_accessed_product !== window.location.pathname) {
            history.push(last_accessed_product);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        },
      });
    } else {
      setStatus({
        submitting: false,
        info: { error: true, msg: msg },
      });
    }
  };

  const handleNameChange = (e) => {
    const val = e.target.value;
    if (!/[^a-zA-Z]/.test(val)) {
      setValue({
        ...value,
        [e.target.name]: val,
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: "",
      });
    }

    if (val.length < 3) {
      setError({
        ...error,
        [e.target
          .name]: `${e.target.placeholder} cannot be less than three characters`,
      });
    } else {
      setError({
        ...error,
        [e.target.name]: "",
      });
    }
  };

  const handleEmailChange = (e) => {
    const reg = /\S+@\S+\.\S+/;
    const val = e.target.value;
    setValue({ ...value, email: val });

    if (reg.test(String(val).toLowerCase())) {
      setError({ ...error, email: "" });
    } else {
      setError({
        ...error,
        email: "Please enter a valid email address",
      });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const val = e.target.value;
    const reg = /^[0-9]+$/;

    if (reg.test(val)) {
      setValue({ ...value, phoneNumber: val });
    }

    if (val.length < 11 || val.length > 11) {
      setError({
        ...error,
        phoneNumber:
          "Your phone number should not be more or less than 11 digits",
      });
    } else if (isNaN(val)) {
      setError({
        ...error,
        phoneNumber: "Your phone number is invalid",
      });
    } else {
      setError({
        ...error,
        phoneNumber: "",
      });
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    setModalVisible(visible);
    if (
      value.firstName &&
      value.lastName &&
      value.email &&
      value.phoneNumber &&
      !error.firstName &&
      !error.lastName &&
      !error.email &&
      !error.phoneNumber
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [visible, value, error]);

  return (
    <>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        okText="Submit"
        confirmLoading={status.submitting}
        okButtonProps={{ disabled: isDisabled }}
        onOk={() => handleGift()}
      >
        <Col>
          <h1>A gift awaits YOU!</h1>
          <h4>Complete your purchase to redeem</h4>
        </Col>
        <FormWrapper>
          <Row>
            <Col span={12} style={{ padding: 5 }}>
              <label htmlFor="firstName">First Name</label>
              <FormInput
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={handleNameChange}
              />
              <small>{error.firstName}</small>
            </Col>
            <Col span={12} style={{ padding: 5 }}>
              <label htmlFor="lastName">Last Name</label>
              <FormInput
                type="text"
                name="lastName"
                placeholder="Last Name"
                onChange={handleNameChange}
              />
              <small>{error.lastName}</small>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ padding: 5 }}>
              <label htmlFor="email">Email Address</label>
              <FormInput
                type="email"
                name="email"
                placeholder="Email Address"
                onChange={handleEmailChange}
              />
              <small>{error.email}</small>
            </Col>
            <Col span={12} style={{ padding: 5 }}>
              <label htmlFor="phoneNumber">Phone Number</label>
              <FormInput
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                onChange={handlePhoneNumberChange}
              />
              <small>{error.phoneNumber}</small>
            </Col>
          </Row>
        </FormWrapper>
        {status.info.error && (
          <div className="error">Error: {status.info.msg}</div>
        )}
        {/* {!status.info.error && status.info.msg && <p>{status.info.msg}</p>} */}
      </Modal>
    </>
  );
};

export default FreeGift;
