import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/yermassure.png";
import EndowmentPlan from "../../components/EndowmentPlan";

const Index = () => {
  const bannerPayload = {
    title: "Heirs Endowment Plan",
    descriptions: [
      "The Heirs Endowment plan is like using one stone to kill two birds- it helps you save towards a future goal, while leaving an inheritance for your family.",
      "Here is how it works: ",
      "You choose a target amount you want in the future and start saving any amount (from N5,000 and above) towards it. If anything happens to you, even on day 2 of this plan, Heirs Life will pay your beneficiary the target bulk amount which you chose at the onset of the plan, plus bonus.",
      "If you survive the plan, at the maturity period, Heirs Life will pay you the bulk amount which you chose at the onset of the plan, plus bonus.",
      "The Heirs Endowment Plan can be taken by anyone who wants to save towards a big financial project while securing wealth for their family in case of the unexpected.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#credit",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <EndowmentPlan />
    </div>
  );
};

export default Index;
