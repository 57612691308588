import React from "react";
import { InputWrapper, SelectWrapper, Option } from "./style";

const Input = ({ ...rest }) => {
  return <InputWrapper {...rest} />;
};

export const Select = ({ ...rest }) => {
  return <SelectWrapper {...rest} />;
};

export const OptionWrapper = ({ ...rest }) => {
  return <Option {...rest} />;
};

export default Input;
