import React from "react";
import Banner from "./Banner";
import backgroundImage from "../../assets/images/get-answers.png";
import { Wrapper } from "./styles";
import CorporateGovernance from "./CorporateGovernance";

const Index = () => {
  const bannerPayload = {
    title: "Investor Relations",
    children: "Stay up to date on trends and recent happenings in insurance.",
    backgroundImage,
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />

      <CorporateGovernance />
    </Wrapper>
  );
};

export default Index;
