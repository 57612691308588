import React from "react";
import Section from "../../Section";
import { Row, Col } from "antd";
import {
  Wrapper,
  Title,
  Content,
  TextContent,
  ContentWrapper,
  DownloadWrapper,
  TextMessage,
} from "./style";
// import Button from "../Button";
import callBackImage from "../../../assets/images/bancassurance-call.png";
import appleStore from "../../../assets/images/appstore.png";
import googlePlay from "../../../assets/images/playstore.png";

import { Tooltip } from "antd";
// import Form from "./Form";
// import { Mixpanel } from "./../../Mixpanel";

let isSet = false;

const handleClick = () => {
  isSet = !isSet;
};

const Testimonial = () => {
  return (
    <Wrapper>
      <Section>
        <ContentWrapper>
          <Content>
            <TextContent>
              <Title style={{ fontSize: "40px" }}>Got Questions?</Title>
              <Row gutter={0}>
                {/* <Col sm={8} xs={12}> */}
                <TextMessage>
                  <div
                    // href="./contact"
                    style={{
                      textAlign: "center",
                      display: "block",
                      background: "#000",
                      color: "#fff",
                      padding: "32px 64px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      cursor: "default",
                      fontSize: "20px",
                    }}
                  >
                    Call Us Now: <br />
                    <b>07004347754</b>
                  </div>
                  {/*  We are all about simplifying experiences for you. So, when
                  next you walk into a UBA branch, remember that you can also
                  transact insurance without stress. */}
                </TextMessage>
                {/* </Col> */}
                {/* <Col sm={8} xs={12}>
                  <img
                    src={appleStore}
                    alt={"apple"}
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                  />
                  <img src={googlePlay} alt={"google"} />
                </Col> */}
                {/* <Col span={24} md={12}>
                  <a
                    // onClick={Mixpanel.track("Call Us Now")}
                    onClick={handleClick()}
                    href="#complaint"
                  >
                    Send a Message
                  </a>
                  {/* </Tooltip> */}
                {/* </Col> */}
              </Row>
            </TextContent>

            <div>
              <img src={callBackImage} alt="call back" />
            </div>
          </Content>
        </ContentWrapper>
      </Section>
    </Wrapper>
  );
};

export default Testimonial;
