import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/heirs-whole-life.png";
import AnnuityPlanPicker from "../../components/AnnuityPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Annuity Plan",
    descriptions: [
      "The Heirs Life Pension-regulated Annuity plan is specially designed for retirees aged 50 and above who want to earn a steady, life-long income, after retirement.",
      "At retirement, you are given the option to transfer your pension funds to purchase an Annuity plan. If you buy the Heirs Life Annuity plan, we pay you a steady income for as long as you live.",
      "Unlike Programmed withdrawals (Pension) which pays you until you exhaust your retirement savings balance, Annuity keeps paying you a steady amount for life and does not get exhausted.",
      "If there is loss of life within the first 10 years of the policy, the balance is prorated and paid as a bulk amount to your beneficiary. This way, your hard-earned money still stays with your loved ones.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    productURL: "https://www.youtube.com/embed/5qM3MrEfR58",
    linkPath: "#annuity",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <AnnuityPlanPicker />
    </div>
  );
};

export default Index;
