import React from "react";
import { Row } from "antd";
import Plan from "./Plan";
import { BigText, PlansContainer } from "../style";
// import { ReactComponent as TargetIcon } from "../../../assets/images/target.svg";
import { ReactComponent as PaymentIcon } from "../../../assets/images/payment-method.svg";
// import { ReactComponent as ABCBlockIcon } from "../../../assets/images/abc-block.svg";
import { ReactComponent as ParentsIcon } from "../../../assets/images/parents.svg";
import { ReactComponent as SalaryPlusIcon } from "../../../assets/images/salary-plus.svg";
import { ReactComponent as TermLifeIcon } from "../../../assets/images/term-life.svg";
import { ReactComponent as MyHeirsIcon } from "../../../assets/images/my-heirs.svg";
import { ReactComponent as WholeLifeIcon } from "../../../assets/images/whole-life.svg";
import { ReactComponent as EntrepreneurIcon } from "../../../assets/images/entrepreneur.svg";
import { ReactComponent as SchoolPlanIcon } from "../../../assets/images/school-plan.svg";
// import { ReactComponent as LoanProtectIcon } from "../../../assets/images/loan-protect.svg";
import { ReactComponent as HeirsSaveIcon } from "../../../assets/images/Heirs_save.svg";
import { ReactComponent as GroupLifeIcon } from "../../../assets/images/group-life.svg";
import { ReactComponent as LoanIcon } from "../../../assets/images/loan.svg";
import { ReactComponent as MortgageIcon } from "../../../assets/images/mortgage.svg";
import { ReactComponent as KeymanIcon } from "../../../assets/images/keyman.svg";

const PlanItems = () => {
  const plans = [
    // {
    //   title: "Target Savings",
    //   icon: TargetIcon,
    //   price: "N5,000",
    //   url: "/product",
    // },
    {
      title: "Triple Pay Investment Plan",
      icon: PaymentIcon,
      price: "N5,000",
      url: "/triple-pay",
      mixpanel: "Triple Pay",
      quote:
        "https://simple.heirslifeassurance.com/savings-type?policy-type=Heirs%20Triple%20Pay%20Plan",
      req: "1",
    },
    // {
    //   title: "Children Education Plus",
    //   icon: ABCBlockIcon,
    //   price: "N3,000",
    //   url: "/product",
    // },
    {
      title: "Couples Investment",
      icon: ParentsIcon,
      price: "N10,000",
      url: "/couples",
      mixpanel: "Couples Investment",
      quote:
        "https://simple.heirslifeassurance.com/savings-type?policy-type=Heirs%20Couple%20Plan",
      req: "1",
    },
    {
      title: "Salary Plus",
      icon: SalaryPlusIcon,
      price: "N5,000",
      url: "/salary",
      mixpanel: "Salary Plus",
      quote:
        "https://simple.heirslifeassurance.com/savings-type?policy-type=Heirs%20Salary%20plus",
      req: "1",
    },
    {
      title: "Term Life",
      icon: TermLifeIcon,
      price: "N1,000",
      url: "/term-assure",
      mixpanel: "Term Life",
      quote:
        "https://simple.heirslifeassurance.com/family-type?policy-type=Heirs%20Term%20Assure",
      req: "1",
    },
    {
      title: "MyHeirs (Kids Investment)",
      icon: MyHeirsIcon,
      price: "N5,000",
      url: "/my-heirs",
      mixpanel: "MyHeirs (Kids Investment)",
      quote:
        "https://simple.heirslifeassurance.com/children-type?policy-type=MyHeirs%20Plan",
      req: "1",
    },
    {
      title: "Whole Life",
      icon: WholeLifeIcon,
      price: "N5,000",
      url: "/whole-life",
      mixpanel: "Whole Life",
      quote:
        "https://simple.heirslifeassurance.com/family-type?policy-type=Heirs%20Whole%20Life%20Assurance",
      req: "1",
    },
    {
      title: "Entrepreneurs Investment",
      icon: EntrepreneurIcon,
      price: "N20,000",
      url: "/entrepreneur",
      mixpanel: "Entrepreneurs Investment",
      quote:
        "https://simple.heirslifeassurance.com/business-type?policy-type=Heirs%20Entreprenuers%20Plan",
      req: "1",
    },
    {
      title: "Smart School Plan",
      icon: SchoolPlanIcon,
      price: "N3,000",
      url: "/smart-school",
      mixpanel: "Smart School Plan",
      quote:
        "https://simple.heirslifeassurance.com/family-type?policy-type=Heirs%20Smart%20School%20Plan",
      req: "1",
    },
    // {
    //   title: "Loan Protection Plan",
    //   icon: LoanProtectIcon,
    //   price: "N3,000",
    //   url: "/product",
    // },
    {
      title: "Heirs Save Plan",
      icon: HeirsSaveIcon,
      price: "N5,000",
      url: "/heirs-save",
      mixpanel: "Heirs Save Plan",
      quote:
        "https://simple.heirslifeassurance.com/savings-type?policy-type=Heirs%20Save%20Plan",
      req: "1",
    },
    {
      title: "Heirs Surgery Care",
      icon: LoanIcon,
      price: "N5,000",
      url: "/surgery",
      mixpanel: "Heirs Surgery Care",
      quote:
        "https://simple.heirslifeassurance.com/health-type?policy-type=Heirs%20Surgery%20Care%20Plan",
      req: "1",
    },
    {
      title: "Heirs Endowment Plan",
      icon: LoanIcon,
      price: "N5,000",
      url: "/endowment",
      mixpanel: "Heirs Endowment Plan",
      quote:
        "https://simple.heirslifeassurance.com/savings-type?policy-type=Heirs%20Endowment%20Assurance",
      req: "1",
    },
    {
      title: "Heirs Annuity Plan",
      icon: HeirsSaveIcon,
      price: "",
      url: "/annuity",
      mixpanel: "Heirs Annuity Plan",
      quote: "https://simple.heirslifeassurance.com/annuity-option",
      req: "2",
    },
    {
      title: "Heirs Hospital Cash",
      icon: HeirsSaveIcon,
      price: "",
      url: "/hospital-cash",
      mixpanel: "Heirs Hospital Cash",
      quote:
        "https://simple.heirslifeassurance.com/health-type?policy-type=Heirs%20Hospital%20Cash%20Basic%20Plan",
      req: "2",
    },
    {
      title: "Heirs Annuity Lite",
      icon: HeirsSaveIcon,
      price: "",
      url: "/annuity-lite",
      mixpanel: "Heirs Annuity Lite",
      quote:
        "https://simple.heirslifeassurance.com/annuity-option?policy-type=Heirs%20Annuity%20Lite",
      req: "2",
    },
  ];
  const corporatePlans = [
    {
      title: "Group Life",
      icon: GroupLifeIcon,
      price: "N3,000",
      url: "/group-life",
      mixpanel: "Home Group Life Button",
      quote: "",
      req: "4",
    },
    {
      title: "Credit Life",
      icon: LoanIcon,
      price: "N5,000",
      url: "/credit-life",
      mixpanel: "Credit Life",
      quote:
        "https://simple.heirslifeassurance.com/protection-type?policy-type=Heirs%20Credit%20Life%20Assurance",
      req: "3",
    },
    {
      title: "Mortgage Protection",
      icon: MortgageIcon,
      price: "N3,000",
      url: "/mortgage",
      mixpanel: "Mortgage Protection",
      quote:
        "https://simple.heirslifeassurance.com/protection-type?policy-type=Heirs%20Mortgage%20Protection%20Assurance",
      req: "2",
    },
    {
      title: "Heirs Keyman Assurance",
      icon: KeymanIcon,
      price: "N3,000",
      url: "/keyman-assurance",
      mixpanel: "Heirs Keyman Assurance",
      quote:
        "https://simple.heirslifeassurance.com/business-type?policy-type=Heirs%20Keyman%20Assurance",
      req: "3",
    },
  ];

  return (
    <PlansContainer>
      <BigText>For Individuals</BigText>
      <Row gutter={[16, 0]} align={"center"}>
        {plans.map(({ title, price, icon, url, mixpanel, quote, req }, key) => (
          <Plan
            mixpanel={mixpanel}
            title={title}
            price={price}
            icon={icon}
            url={url}
            key={key}
            quote={quote}
            req={req}
          />
        ))}
      </Row>

      <BigText>For Corporate</BigText>
      <Row gutter={[16, 0]} align={"center"}>
        {corporatePlans.map(
          ({ title, price, icon, url, mixpanel, quote, req }, key) => (
            <Plan
              mixpanel={mixpanel}
              title={title}
              price={price}
              icon={icon}
              url={url}
              key={key}
              quote={quote}
              req={req}
            />
          )
        )}
      </Row>
    </PlansContainer>
  );
};

export default PlanItems;
