import styled from "styled-components";
import { ButtonWrapper } from "../Button/style";
import { InputWrapper } from "../Input/style";
import * as Colors from "../../utils/colors";

export const FormContainer = styled("div")`
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 32rem;

  h1 {
    margin-bottom: 10px;
  }
`;

export const FormOverlay = styled("div")`
  position: absolute;
  height: inherit;
  width: 100%;
  background: rgba(18, 18, 18, 0.72);
  padding: 50px 0;

  @media screen and (max-width: 575px) {
    padding: 25px 0;
  }
`;

export const FormWrapper = styled("div")`
  width: 45%;
  margin: 0 auto;
  height: 100%;

  @media screen and (max-width: 575px) {
    width: 90%;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  ${InputWrapper} {
    width: 100%;
    border: solid 1px ${Colors.DARK} !important;
    margin-right: 0 !important;

    .ant-input {
      width: 100% !important;
    }
  }

  ${ButtonWrapper} {
    font-family: "Paralucent", sans-serif !important;
  }

  .description {
    text-align: center;
    padding-bottom: 2rem;

    h1,
    p {
      color: ${Colors.WHITE} !important;
    }
  }

  .ant-form-item-label > label {
    color: ${Colors.WHITE} !important;
  }

  .ant-checkbox + span {
    color: ${Colors.WHITE} !important;
  }

  .ant-form-item .ant-form-item {
    margin-bottom: 0;
  }

  small {
    color: ${Colors.PRIMARY};
  }
`;

export const SubscribeHeader = styled("h1")`
  color: ${Colors.WHITE} !important;
  margin-bottom: 2rem !important;
  font-size: 3rem;
`;

export const SuccessfulWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  p {
    color: ${Colors.WHITE} !important;
    margin-bottom: 20px;
  }
`;
