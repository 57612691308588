import React from "react";
import { Wrapper, Title, SubTitle, Text } from "./style";

const Section = ({
  title,
  preTitle,
  subTitle,
  subTitlePrefix,
  children,
  filled,
}) => {
  return (
    <Wrapper filled={filled}>
      {preTitle && <Text>{preTitle}</Text>}
      {title && <Title>{title} </Title>}
      {subTitle && (
        <SubTitle>
          {subTitlePrefix && <span>{subTitlePrefix}&nbsp;</span>}
          {subTitle}
        </SubTitle>
      )}

      {children}
    </Wrapper>
  );
};

export default Section;
