import React, { useEffect, useState } from "react";
// import Banner from "../../components/ProductBanner";
import Banner from "./banner.js";
import backgroundImage from "../../assets/images/mechanic.jpg";
import { Text, Title, Wrapper, List } from "../../components/PlanPicker/styles";
// import Ussd from "./ussd.js";
// import HospitalCashPlanPicker from "../../components/HospitalCashPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Exclusive! Here are 5 Things Mechanics Will not Tell You",
    descriptions: [
      "When it comes to our beloved cars, we trust mechanics to be our knights in shining armour, the experts who can diagnose and fix any issues that arise even just by describing the problem. However, ...",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#read",
    // linkText: "Watch how it works",
    linkText: "Read More",
  };

  return (
    <div>
      <Banner {...bannerPayload} />

      {/* <Ussd /> */}
    </div>
  );
};

export default Index;
