import React from "react";
import { Tooltip, Row } from "antd";
import { useState } from "react";
import { CircleWrapper, Circle, Channel, CircleBody } from "./styles";
import EmailIcon from "../../assets/images/icons/email.png";
import EmailInvertedIcon from "../../assets/images/icons/email-inverted.png";
import Phone2Icon from "../../assets/images/icons/phone-2.png";
import Phone2InvertedIcon from "../../assets/images/icons/phone-2-inverted.png";
import Web2Icon from "../../assets/images/icons/web-2.png";
import Web2InvertedIcon from "../../assets/images/icons/web-2-inverted.png";
import WhatsappIcon from "../../assets/images/icons/whatsapp.png";
import WhatsappInvertedIcon from "../../assets/images/icons/whatsapp-inverted.png";
import FacebookIcon from "../../assets/images/icons/facebook.png";
import FacebookInvertedIcon from "../../assets/images/icons/facebook-inverted.png";
import InstagramIcon from "../../assets/images/icons/instagram.png";
import InstagramInvertedIcon from "../../assets/images/icons/instagram-inverted.png";
import TwitterIcon from "../../assets/images/icons/twitter.png";
import TwitterInvertedIcon from "../../assets/images/icons/twitter-inverted.png";
import UssdIcon from "../../assets/images/icons/ussd.png";
import UssdInvertedIcon from "../../assets/images/icons/ussd-inverted.png";

import backgroundImage from "../../assets/images/digital-channels.png";

const DigitalChannels = () => {
  const [channelHovered, setChannelHover] = useState(false);
  const [channelType, setChannelType] = useState(null);
  const channelPayload = [
    {
      type: "email",
      url: "mailto:wecare@heirslifeassurance.com",
      tooltip: "wecare@heirslifeassurance.com",
      icon: EmailIcon,
      iconInverted: EmailInvertedIcon,
    },
    {
      type: "phone",
      url: "tel:0700-434-7754",
      tooltip: "0700-434-7754",
      icon: Phone2Icon,
      iconInverted: Phone2InvertedIcon,
    },
    {
      type: "ussd",
      url: "/ussd",
      tooltip: "*1100#",
      icon: UssdIcon,
      iconInverted: UssdInvertedIcon,
    },
    {
      type: "web",
      url: "https://www.heirslifeassurance.com",
      tooltip: "www.heirslifeassurance.com",
      icon: Web2Icon,
      iconInverted: Web2InvertedIcon,
    },
    {
      type: "whatsapp",
      url: "https://wa.me/2349122222200",
      tooltip: "0912 222 2200",
      icon: WhatsappIcon,
      iconInverted: WhatsappInvertedIcon,
    },
    {
      type: "facebook",
      url: "https://www.facebook.com/heirsinsurancegrou",
      tooltip: "@heirs insurance group",
      icon: FacebookIcon,
      iconInverted: FacebookInvertedIcon,
    },
    {
      type: "instagram",
      url: "https://www.instagram.com/heirsinsurancegroup/",
      tooltip: "@heirsinsurancegroup",
      icon: InstagramIcon,
      iconInverted: InstagramInvertedIcon,
    },
    {
      type: "twitter",
      url: "https://twitter.com/HeirsInsurance_",
      tooltip: "@HeirsInsurance_",
      icon: TwitterIcon,
      iconInverted: TwitterInvertedIcon,
    },
  ];
  return (
    <CircleBody>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          minHeight: "100vh",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <CircleWrapper backgroundImage={backgroundImage}>
          <Circle>
            {channelPayload.map(
              ({ type, icon, iconInverted, url, tooltip }, key) => (
                <Channel
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(encodeURI(url), "_blank").focus();
                  }}
                  onMouseOver={() => {
                    setChannelType(`${type}`);
                    setChannelHover(true);
                  }}
                  onMouseOut={() => {
                    setChannelType(null);
                    setChannelHover(false);
                  }}
                >
                  <Tooltip title={tooltip} color={"red"} key={key}>
                    <img
                      src={
                        channelType === type && channelHovered
                          ? iconInverted
                          : icon
                      }
                      alt={type}
                    />
                  </Tooltip>
                </Channel>
              )
            )}
          </Circle>
        </CircleWrapper>
      </Row>
    </CircleBody>
  );
};

export default DigitalChannels;
