import React from "react";
import Banner from "./Banner";
import Plans from "../../components/PlansList";
import Testimonial from "../../components/Testimonial";
import CallBack from "../../components/CallBack";
import WhyChooseHeirs from "../../components/WhyChooseHeirs";
// import BlogSection from "../../components/BlogSection";
import backgroundImage from "../../assets/images/home-banner.png";

const Index = () => {
  const bannerPayload = {
    title: "If you have a dream we have a plan for it.",
    descriptions: [],
    backgroundImage,
    linkPath: "#planchoice",
    linkText: "Get Started",
  };

  return (
    <div>
      <Banner {...bannerPayload} />

      <Plans />

      <WhyChooseHeirs />

      <Testimonial />

      {/* <BlogSection /> */}

      {/* <CallBack /> */}
    </div>
  );
};

export default Index;
