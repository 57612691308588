import React from "react";
import MyHeirsPlanPicker from "../../components/MyHeirsPlanPicker";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/My-heirs.png";

const Index = () => {
  const bannerPayload = {
    title: "MyHeirs",
    descriptions: [
      "This is the perfect plan for parents who want to secure their child's future, especially for their education. It has two variants: MyHeirs & MyHeirs Plus.",
      "MyHeirs combines periodic savings and life insurance to cover the expenses of your child’s future needs. You choose a target amount you want for your child and start saving an amount periodically, earning interest on it. If anything happens to you, your child gets the total target amount plus total savings plus interest. If you survive the duration of the plan, you get total savings plus interest.",
      "With MyHeirs Plus, you choose a target amount you want your child to inherit in the future and start paying an amount periodically, depending on your age. If anything happens to you, even on day 2 of the plan, your child gets the total target amount plus bonus. If you survive the duration of the plan, you get the total target amount plus bonus. The bonus is secured upon complete payment of yearly premium.",
      "This way, your dreams for them continue as planned, no matter what happens.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#heirs",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <MyHeirsPlanPicker />
    </div>
  );
};

export default Index;
