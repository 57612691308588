import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/hospital-cash.png";
import HospitalCashPlanPicker from "../../components/HospitalCashPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Hospital Cash Plan",
    descriptions: [
      "Hospital bills are on the rise. If you are sick and get admitted into the hospital, we will pay you a lump sum of money to cushion the loss of income you might suffer for the period you cannot go to work.",
      "The Hospital Cash Plan pays you up to a maximum of N200,000 depending on your plan, when you get hospitalised for a minimum of 3 days. This way, you don't lose income while you're admitted at the hospital.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#details",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <HospitalCashPlanPicker />
    </div>
  );
};

export default Index;
