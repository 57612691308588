import React, { useEffect, useState } from "react";
import { List, Spin, Avatar, Card, Skeleton, Switch, Button } from "antd";
import {
  AnswerItemWrapper,
  AnswersWrapper,
  ImageContainer,
  Text,
  Title,
} from "./styles";
import { dateFormat } from "../../utils/dateFormatter";
import { ContentLoader } from "../MediaPost/styles";
import CallBack from "../../components/CallBack";

const FinancialStatements = () => {
  const [posts, setPosts] = useState([]);
  const [posts2, setPosts2] = useState([]);
  const [posts3, setPosts3] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPosts = (pageNum) => {
    setPosts([
      {
        ID: 8,
        title:
          "HLA - 2022 Investor Relations Evaluation Statement for Annual Report",
        description:
          "HLA - 2022 Investor Relations Evaluation Statement for Annual Report. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        // url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/Ecn6_vDwC2RIiqKFofcTLLYBYvw-1KYvd_s5siEIaCzsPA?e=HhiUS7",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/Ecn6_vDwC2RIiqKFofcTLLYBYvw-1KYvd_s5siEIaCzsPA",
      },
      {
        ID: 3,
        title:
          "HLA - 2021 Investor Relations Evaluation Statement for Annual Report",
        description:
          "HLA - 2021 Investor Relations Evaluation Statement for Annual Report. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/ESWuOCXblU5KtNJ2Atr-RzIBdsYshvF8TUO_HZ63-15GZg?e=gKpgAc",
      },
      /* {
        ID: 5,
        title: "HLA - Corporate Communications Policy",
        description:
          "HLA - Corporate Communications Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EU3w6cNQ_mROpryX-1__5PsBrKZvtSKo_BKPjpyH0KXQgg?e=0ZYDdw",
      }, */
    ]);
    setIsLoading(false);
  };

  const fetchPosts2 = (pageNum) => {
    setPosts2([
      {
        ID: 7,
        title: "HLA - 2022 Board Evaluation Statement for Annual Report",
        description:
          "HLA  - 2022 Board Evaluation Statement for Annual Report. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        // url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EW-F1og3JDhBv7lJ8gnJytwBaYKhZmRian91OlKOwlPmng?e=4cfuII",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EW-F1og3JDhBv7lJ8gnJytwBaYKhZmRian91OlKOwlPmng",
      },
      {
        ID: 4,
        title: "HLA - 2021 Board Evaluation Statement for Annual Report",
        description:
          "HLA  - 2021 Board Evaluation Statement for Annual Report. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/EbnEboMVditAq-h1fv0h5lEBpy67OzE4Sq_b4pXkQM-ZMA?e=HWqIxX",
      },
      {
        ID: 1,
        title: "HLA - Stakeholders Management and Communications Policy",
        description:
          "HLA - Stakeholders Management and Communications Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EQveQRL_KkJOjelbR1M68XABJUpqwB3buIpuaWPekpsI6g?e=813SMb",
        // url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/ESeaatpScSlNjyJlWXafE_8BFCaTVYvCnJ24241-s4IMaw?e=ZCUXtg",
      },
    ]);
    setIsLoading(false);
  };

  const fetchPosts3 = (pageNum) => {
    setPosts3([
      {
        ID: 6,
        title: "HLA - Financial Statement For The Year Ending December 2022",
        description:
          "HLA - Financial statement for the year ending december, 2022. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        // url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/ES_qHtASmLVLhgVtp9N7xqkBwrNcLUcmTeiGTCtAJw6avQ?e=rtg4Lc",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/ES_qHtASmLVLhgVtp9N7xqkBwrNcLUcmTeiGTCtAJw6avQ",
      },
      {
        ID: 5,
        title:
          "HLA - 13 Months Financial Statement Reports Ending December 2021",
        description:
          "HLA - 13 months financial statement reports ending december, 2021. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EbQZSdlrsvpLlNSqwI9wswMBnmNvnQFqoKDzOKdbgj_6oA?e=FjUSGv",
        // url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EV8cYgEqcpFDr_dh3Ktw3-IBz2M__Gch8vp5-GR7aDp40w?e=4hPv00",
      },
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    fetchPosts2();
  }, []);

  useEffect(() => {
    fetchPosts3();
  }, []);

  return (
    <>
      <AnswersWrapper>
        <div
          style={{ fontWeight: "bold", fontSize: "18pt", paddingLeft: "20px" }}
        >
          REGULATORY ARCHIVE
        </div>
        <hr></hr>
        {!isLoading ? (
          <>
            <List
              // pagination={{
              //   position: "bottom",
              //   total: totalPosts,
              //   pageSize: pageSize,
              //   current: currentPage,
              //   onChange: (page) => {
              //     handlePageChange(page);
              //   },
              // }}
              grid={{
                gutter: 64,
                column: 2,
              }}
              dataSource={posts}
              renderItem={(item) => (
                <List.Item key={item.ID}>
                  <Card
                    style={{ width: 500, marginTop: 16 }}
                    actions={[
                      <Button
                        onClick={() => {
                          window.open(item.url);
                        }}
                      >
                        View Report
                      </Button>,
                    ]}
                  >
                    <Card.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </Card>
                </List.Item>
              )}
            />
          </>
        ) : (
          <ContentLoader>
            <Spin size="large" />
          </ContentLoader>
        )}
      </AnswersWrapper>
      {/* <CallBack /> */}

      <AnswersWrapper>
        <div
          style={{ fontWeight: "bold", fontSize: "18pt", paddingLeft: "20px" }}
        >
          FINANCES ARCHIVE
        </div>
        <hr></hr>
        {!isLoading ? (
          <>
            <List
              // pagination={{
              //   position: "bottom",
              //   total: totalPosts,
              //   pageSize: pageSize,
              //   current: currentPage,
              //   onChange: (page) => {
              //     handlePageChange(page);
              //   },
              // }}
              grid={{
                gutter: 64,
                column: 2,
              }}
              dataSource={posts3}
              renderItem={(item) => (
                <List.Item key={item.ID}>
                  <Card
                    style={{ width: 500, marginTop: 16 }}
                    actions={[
                      <Button
                        onClick={() => {
                          window.open(item.url);
                        }}
                      >
                        View Report
                      </Button>,
                    ]}
                  >
                    <Card.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </Card>
                </List.Item>
              )}
            />
          </>
        ) : (
          <ContentLoader>
            <Spin size="large" />
          </ContentLoader>
        )}
      </AnswersWrapper>

      <AnswersWrapper>
        <div
          style={{ fontWeight: "bold", fontSize: "18pt", paddingLeft: "20px" }}
        >
          BOARD ARCHIVE
        </div>
        <hr></hr>
        {!isLoading ? (
          <>
            <List
              // pagination={{
              //   position: "bottom",
              //   total: totalPosts,
              //   pageSize: pageSize,
              //   current: currentPage,
              //   onChange: (page) => {
              //     handlePageChange(page);
              //   },
              // }}
              grid={{
                gutter: 64,
                column: 2,
              }}
              dataSource={posts2}
              renderItem={(item) => (
                <List.Item key={item.ID}>
                  <Card
                    style={{ width: 500, marginTop: 16 }}
                    actions={[
                      <Button
                        onClick={() => {
                          window.open(item.url);
                        }}
                      >
                        View Report
                      </Button>,
                    ]}
                  >
                    <Card.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </Card>
                </List.Item>
              )}
            />
          </>
        ) : (
          <ContentLoader>
            <Spin size="large" />
          </ContentLoader>
        )}
      </AnswersWrapper>
    </>
  );
};

export default FinancialStatements;
