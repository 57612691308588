import React from "react";
import Banner from "../../components/ProductBanner";
import WholeLifePlanPicker from "../../components/WholeLifePlanPicker";
import backgroundImage from "../../assets/images/heirs-whole-life.png";

const Index = () => {
  const bannerPayload = {
    title: "Whole Life Plan",
    descriptions: [
      "If you want to guarantee a solid financial future and inheritance for your family, this plan helps you achieve that.",
      "With the Whole Life plan, Heirs Life will pay your family a pre-agreed lump sum of money upon your demise. This way your loved ones can keep living the life you planned for them.",
      `Unlike the <a href="/term-assure">Term Life plan</a> that expires after a short period, the Whole Life plan is valid for a lifetime and will only be terminated if there is loss of life.`,
      "We all do not live forever. With the Whole Life plan, there is 100% guarantee that your family will inherit an amount from you when the inevitable happens.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#whole-life",
    productURL:
      "https://www.youtube.com/embed/y2YmQdbPCTk?list=PLKzX6UG7Pf2kTUGTjEUfl1J79w2wVr0Gl&rel=0&controls=0",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <WholeLifePlanPicker />
    </div>
  );
};

export default Index;
