import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Wrapper = styled("div")`
  padding: 40px 0;
  ${containerWrapper};

  img {
    width: 100%;
  }
`;

export const IconWrapper = styled("div")`
  text-align: center;

  img {
    width: 100%;
  }
  .anticon {
    display: block;
  }

  svg {
    width: auto;
    height: 200px;
  }
`;

export const Content = styled("div")`
  padding: 35px 0;

  ${containerWrapper}
`;

export const Card = styled("div")`
  padding: 15px;
  background: ${Colors.WHITE};
  height: 100%;
  margin: 0 auto 30px;
  text-align: center;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    padding-bottom: 40px;
  }
`;

export const Text = styled("p")`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  font-family: "Mulish Bold", sans-serif;
`;

export const SubText = styled("p")`
  text-align: center;
  font-size: 18px;
`;

export const CircleBody = styled("div")`
  @media screen and (max-width: 576px) {
    margin-top: -200px;
    margin-bottom: -200px;
  }
  @media screen and (max-width: 380px) {
    margin-top: -100px;
    margin-bottom: -100px;
  }
`;

export const CircleWrapper = styled("div")`
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
  justify-content: center;
  display: inline-block;
  position: relative;
  @media screen and (max-width: 576px) {
    background-size: 150px;
  }
  @media screen and (max-width: 300px) {
    background-size: 100px;
  }
`;

export const Circle = styled("div")`
  border: 0px solid #d87272;
  border-radius: 50%;
  position: relative;
  width: 500px;
  height: 500px;
`;

export const Channel = styled("div")`
  --n: 8; /* number of item */
  --d: 150s; /* duration */
  position: absolute;
  left: calc(50% - 37px);
  top: calc(50% - 37px);
  height: 70px;
  width: 70px;
  border: 0px solid #d87272;
  border-radius: 50%;
  grid-area: 1/1;
  text-align: center;
  animation-fill-mode: forwards;
  animation: rotate var(--d) linear infinite;

  --transform: 200px;
  @media screen and (max-width: 576px) {
    --transform: 120px;
    left: calc(50% - 31px);
    top: calc(50% - 31px);
    height: 60px;
    width: 60px;
  }
  @media screen and (max-width: 300px) {
    --transform: 85px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;
  }
  transform: rotate(0) translate(var(--transform)) rotate(0);
  @keyframes rotate {
    100% {
      transform: rotate(1turn) translate(var(--transform)) rotate(-1turn);
    }
  }
  &:nth-child(1) {
    animation-delay: calc(-0 * var(--d) / var(--n));
  }
  &:nth-child(2) {
    animation-delay: calc(-1 * var(--d) / var(--n));
  }
  &:nth-child(3) {
    animation-delay: calc(-2 * var(--d) / var(--n));
  }
  &:nth-child(4) {
    animation-delay: calc(-3 * var(--d) / var(--n));
  }
  &:nth-child(5) {
    animation-delay: calc(-4 * var(--d) / var(--n));
  }
  &:nth-child(6) {
    animation-delay: calc(-5 * var(--d) / var(--n));
  }
  &:nth-child(7) {
    animation-delay: calc(-6 * var(--d) / var(--n));
  }
  &:nth-child(8) {
    animation-delay: calc(-7 * var(--d) / var(--n));
  }
`;
