import axios from "axios";
import { useState, useEffect } from "react";
import { HeirsFetchPostsUrl } from "../utils/constant";

const useSubscribeNewsletter = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState({
    email: "",
    username: "",
    checked: "",
  });

  useEffect(() => {
    if (error.username || error.email) {
      setIsLoading(false);
    }
  }, [error]);

  const handleCheckedChange = (e) => {
    const val = e.target.checked;
    setIsChecked(val);

    if (val === true) {
      setError({
        ...error,
        checked: "",
      });
    }
  };

  const handleEmailAddressChange = (e) => {
    const reg = /\S+@\S+\.\S+/;
    const val = e.target.value;
    setEmailAddress(val);

    if (reg.test(String(val).toLowerCase())) {
      setError({ ...error, email: "" });
    } else {
      setError({
        ...error,
        email: "please enter a valid email address",
      });
    }
  };

  const handleNameChange = (e) => {
    const val = e.target.value;
    setFirstName(val);

    if (val.length > 3 && /^[a-zA-Z]+$/.test(val)) {
      setError({ ...error, username: "" });
    }
    if (val.length < 3) {
      setError({
        ...error,
        username: "name cannot be less than three characters",
      });
    }
    if (val && !/^[a-zA-Z]+$/.test(val)) {
      setError({ ...error, username: "name should only be letters" });
    }
  };

  const handleSuccessfulButtonClick = () => {
    window.scrollTo(0, 0);
    setIsSuccessful(false);
    setIsChecked(false);
  };

  const handleSubscribeFormSubmit = async (e) => {
    if (isChecked === true && !error.username && !error.email) {
      setIsLoading(true);
      const data = {
        emailAddress,
        firstName,
      };

      try {
        const response = await axios({
          method: "POST",
          url: `${HeirsFetchPostsUrl}/newsletter/life`,
          data,
        });

        if (response && response.data.status === "success") {
          setIsLoading(false);
          setIsSuccessful(true);
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (isChecked === false) {
      setError({
        ...error,
        checked: "Please tick the checkbox above to proceed.",
      });
    }
  };

  return {
    error,
    emailAddress,
    firstName,
    handleCheckedChange,
    handleEmailAddressChange,
    handleNameChange,
    handleSubscribeFormSubmit,
    handleSuccessfulButtonClick,
    isChecked,
    isLoading,
    isSuccessful,
  };
};

export default useSubscribeNewsletter;
