/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Col, Modal, Form } from "antd";
import Icon from "@ant-design/icons";
import {
  PlanWrapper,
  Title,
  Text,
  LinksWrapper,
  LinksWrapperGrey,
  FormWrapper,
  List,
} from "../style";
import { Mixpanel } from "./../../../Mixpanel";
import FormInput from "../../../components/Input";
import axios from "axios";
import { HeirsEmailUrl } from "../../../utils/constant";
import { DatePickerWrapper } from "../../../components/Input/style";

const Plan = ({ title, icon, price, url, mixpanel, quote, req }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  const handleClick = () => {
    Mixpanel.track(mixpanel);
    window.location.pathname = url;
  };

  const handleClick_B = () => {
    Mixpanel.track(mixpanel + " - Buy Now");
  };

  const handleRemind = () => {
    Mixpanel.track("Send Message");
    const data = {
      firstName: "",
      lastName: "",
      email: email,
      phoneNumber: "",
      destination: [
        "wecare@heirslifeassurance.com",
        "nimiweremi.selekere@heirslifeassurance.com",
      ],
      message: date,
      plan: title,
    };
    axios({
      method: "POST",
      url: `${HeirsEmailUrl}/email/others`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          handleServerResponse(true, response.data.message);
          // setTimeout(() => {
          //   history.push("/flexi-motor");
          // }, 5000);
        } else {
          handleServerResponse(false, response.data.message);
        }
      })
      .catch((error) => {
        handleServerResponse(false, "An internal error occured");
      });
  };

  const handleDateChange = (e) => {
    setDate(`Remind me on ${e._d}`);
  };

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
      Array.from(document.querySelectorAll("textarea")).forEach(
        (textarea) => (textarea.value = "")
      );
      // setIsDisabled(false);
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleEmailChange = (e) => {
    console.log("hh", e.target.value);
    setEmail(e.target.value);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const showQuote = () => {
    // setModalVisible(true);
    Mixpanel.track(mixpanel + " - Get Quote");
    window.open(quote);
  };

  const l1 = req == 1 ? "" : "none";
  const l2 = req == 2 ? "" : "none";
  const l3 = req == 3 ? "" : "none";
  const l4 = req == 4 ? "" : "none";
  const l5 = req == 5 ? "" : "none";
  const l6 = req == 6 ? "" : "none";
  quote = quote == "" ? url : quote;

  return (
    <>
      <Col xs={12} sm={8} md={6} lg={4}>
        <PlanWrapper>
          <a href={url} onClick={handleClick}>
            <Icon component={icon} />
          </a>
          <Title>{title}</Title>
          <Text>{price} minimum</Text>
          <LinksWrapper>
            <a onClick={showModal}>Requirements</a>
          </LinksWrapper>
          <LinksWrapper onClick={handleClick_B}>
            <a href={quote}>Buy Now</a>
          </LinksWrapper>
          <LinksWrapperGrey>
            <a onClick={showQuote}>Get Quote</a>
          </LinksWrapperGrey>
        </PlanWrapper>
      </Col>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        okText="Remind me"
        onOk={() => handleRemind(title)}
        footer={null}
      >
        <Col>
          <h1>Documents Required</h1>
        </Col>
        <FormWrapper>
          <Col span={24} style={{ padding: 0 }}>
            <List style={{ display: l1 }}>
              <li>Duly Filled Proposal Form.</li>
              <li>Evidence of Payment.</li>
              <li>
                Valid means of ID - Driver license, Voter's card, NIMC, National
                ID, International Passport.
              </li>
              <li>
                Proof of residence - Utility Bill, Front page of the customer's
                address (same address as indicated on the form or portal), Local
                government levy.
              </li>
              <li>
                Duly Filled CTR, Financial Questionnaire, Address Verification
                Form, Bank Statement not more than 6-months (if premium is more
                than N5 million).
              </li>
              <br />
              <a href={url}>Read More...</a>
            </List>
            <div style={{ display: l2 }}>
              <List>
                <li>A clear copy of your Passport Photograph (mandatory).</li>
                <li>Valid Means of Identification (mandatory).</li>
                <li>
                  Proof of Residence dated within the last 6-months (mandatory).
                </li>
                <li>
                  Retirement Letter from the last place of employment
                  (mandatory).
                </li>
                <li>
                  Evidence of Age (if DoB is not provided on the means of
                  identification) (mandatory).
                </li>
                <li>
                  The RSA Statement/Template (optional, if the premium amount,
                  RSA PEN and PFA name is correctly stated on the proposal
                  form).
                </li>
              </List>
              <p style={{ marginTop: "20px" }}>
                On the third requirement, the client may use the following:
              </p>
              <List>
                <li>Utility Bill - Light, Waste disposal, Water, etc.</li>
                <li>
                  Tenancy Receipt/Agreement that bears your residential address.
                </li>
                <li>Levy Receipt that bears your Residential Address.</li>
              </List>
              <br />
              <a href={url}>Read More...</a>
            </div>
            <List style={{ display: l3 }}>
              <li>Duly Filled Proposal Form.</li>
              <li>Evidence of Payment.</li>
              <li>
                Valid means of ID - Driver license, Voter's card, NIMC, National
                ID, International Passport.
              </li>
              <li>
                Proof of Residence - Utility Bill, Front page of the customer's
                address (same address as indicated on the form or portal), Local
                government levy.
              </li>
              <li>
                Duly Filled CTR, Financial Questionnaire, Address Verification
                Form; Bank Statement not more than 6-months (if Premium is more
                than N5 million).
              </li>
              <li>Evidence of Loan Agreement.</li>
              <li>
                Company's CAC Form, CO2 and CO7 (for Keyman Assurance only).
              </li>
              <br />
              <a href={url}>Read More...</a>
            </List>
            <List style={{ display: l4 }}>
              <li>Duly Filled Proposal Form.</li>
              <li>Evidence of Payment.</li>
              <li>
                Valid Means of ID - Driver License, Voter's Card, NIMC, National
                Passport of one of the Directors.
              </li>
              <li>
                Proof of Residence - Utility Bill, Front page of the customer's
                address (same address as indicated on the form or portal), Local
                Govt. Levy.
              </li>
              <li>
                Duly Filled CTR, Financial Questionnaire, Address Verification
                Form, Bank Statement not more than 6-months (if Premium is more
                than N5 million).
              </li>
              <br />
              <a href={url}>Read More...</a>
            </List>
          </Col>
        </FormWrapper>
      </Modal>
      {/* <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        okText="Remind me"
        onOk={() => handleRemind(title)}
      >
        <Col>
          <h1>Remind me to buy {title}</h1>
        </Col>
        <FormWrapper>
          <Col span={24} style={{ padding: 0 }}>
            <label htmlFor="email_address">Enter your email address</label>
            <FormInput
              type="email"
              name="email_address"
              placeholder="Email Address"
              onChange={handleEmailChange}
            />
          </Col>
          <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
            <Form.Item
              name="incidentDate"
              label="Reminder Date"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter date of reminder",
                },
              ]}
            >
              <DatePickerWrapper onChange={handleDateChange} />
            </Form.Item>
          </Col>
        </FormWrapper>
        {status.info.error && (
          <div className="error">Error: {status.info.msg}</div>
        )}
        {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
      </Modal> */}
    </>
  );
};

export default Plan;
