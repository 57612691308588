import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/Tripple_pay.png";
import TriplePlanPicker from "../../components/TriplePlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Triple Pay Investment",
    descriptions: [
      "The Heirs Triple Pay Investment plan is for smart people who want to save towards a long-term project while still earning during the period.",
      "You pick a target amount you want to earn in the future and start saving periodically towards it. Then Heirs Life pays you three bulk payments within the saving tenure in this order:",
      "1. 25% of the target amount is paid at one-third of the savings period",
      "2. Another 25% of the target amount is paid at two-third of the savings period",
      "3. An additional 100% of the target amount plus bonus is paid at the end of the duration",
      "For peace of mind, if the unfortunate happens to you while saving, your beneficiary still earns the complete 100% of the target amount plus bonus",
    ],
    backgroundImage,
    linkPath:
      "https://www.youtube.com/watch?v=595u_leX3Ho&list=PLKzX6UG7Pf2l6DowD7IxduSlxqEzLGDMi&index=4",
    // linkPath: "#triple",
    linkText: "Watch how it works",
    productURL:
      "https://www.youtube.com/embed/videoseries?list=PLKzX6UG7Pf2kTUGTjEUfl1J79w2wVr0Gl&rel=0&controls=0",
    // linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <TriplePlanPicker />
    </div>
  );
};

export default Index;
