import React from "react";
import Banner from "../../components/ProductBanner";
import PlanPicker from "../../components/PlanPicker";
import backgroundImage from "../../assets/images/product-banner.png";

const Index = () => {
  const bannerPayload = {
    title: "Smart School Plan",
    descriptions: [
      "The Heirs Smart school plan is perfect for parents who want to shield their child's education from uncertainties.",
      "This plan works in partnership with schools to guarantee that Heirs Life pays a child's school fees throughout his/her stay in the school if the parent is unable to do so in a case of death or permanent disability.  This way, education continues.",
      "The Heirs Smart School plan can be taken for students in primary, secondary and tertiary institutions.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#school",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <PlanPicker />
    </div>
  );
};

export default Index;
