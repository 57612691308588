import React, { useEffect } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../PlanPicker/styles";
import {
  TextContentWrapper,
  TitleText,
  TextParagraph,
  ImageWrapper,
} from "./styles";

import bancassurance_icon from "../../assets/images/bancassurance-2.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constant";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  /* const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });
   */

  return (
    <Wrapper id="tenant">
      <ImageWrapper>
        <img src={bancassurance_icon} style={{ width: "100%" }} />
      </ImageWrapper>
      <TextContentWrapper>
        <TitleText>What is Bancassurance?</TitleText>
        <div>
          <TextParagraph>
            Bancassurance is a collaboration between a bank and an insurance
            company that allows the bank to refer insurance products to its
            customers.
          </TextParagraph>
          <TextParagraph>
            With the bancassurance partnership between the United Bank for
            Africa (UBA) Nigeria and Heirs Life, customers of UBA can now buy
            insurance products through UBA's digital channels, across all UBA
            branches, as well as other authorized channels.
          </TextParagraph>
          <TextParagraph>
            <a
              href="https://www.heirslifeassurance.com/blog/faqs-on-bancassurance-partnership-607"
              style={{ fontSize: "12pt" }}
            >
              Frequently Asked Questions on Bancassurance
            </a>
          </TextParagraph>
        </div>
      </TextContentWrapper>
    </Wrapper>
  );
};

export default Index;
