import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/annuity-lite.png";
import AnnuityPlanPicker from "../../components/AnnuityPlanPicker/lite.js";

const Index = () => {
  const bannerPayload = {
    title: "Heirs Annuity Lite Plan",
    descriptions: [
      "Unlike the Annuity plan, the Heirs Annuity Lite Plan is open to anyone aged 45 and above who wants to earn a steady, income for life.",
      "At retirement, you are given the option to transfer your pension funds to purchase an AnThe premium is transferred directly from your personal account to Heirs Life as a single payment, and you will continue to get paid monthly, quarterly, yearly, or biannually for as long as you live.",
      "The policy has two guaranteed period of 5 or 10 years depending on your preference.",
      "This means, if there is loss of life within the first 5 or 10 years of taking the policy, the present value of the balance for the outstanding guaranteed period is paid as a bulk amount to your beneficiary. This way, your hard-earned money still stays with your loved ones.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    // productURL: "https://www.youtube.com/embed/5qM3MrEfR58",
    linkPath: "#annuity",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <AnnuityPlanPicker />
    </div>
  );
};

export default Index;
