import React from "react";
import { Row, Col } from "antd";
import { Wrapper, Content, IconWrapper, Text, Card } from "./style";
import Section from "../Section";
import Lottie from "lottie-react-web";
import quoteAnimation from "../../assets/animations/heirs-life-vector1.json";
import affordableAnimation from "../../assets/animations/heirs-life-vector3.json";
import rewardAnimation from "../../assets/animations/heirs-life-vector2.json";

const defaultOptions = {
  loop: true,
};

const Index = () => {
  return (
    <Wrapper>
      <Content>
        <Section title="Why Choose Heirs Life Assurance?" filled>
          <Row gutter={[32, 0]}>
            <Col md={8} xs={24}>
              <Card>
                <IconWrapper>
                  <Lottie
                    width="100%"
                    options={{
                      ...defaultOptions,
                      animationData: affordableAnimation,
                    }}
                  />
                </IconWrapper>
                <Text>Simple & Affordable Plans</Text>
              </Card>
            </Col>
            <Col md={8} xs={24}>
              <Card>
                <IconWrapper>
                  <Lottie
                    width="100%"
                    options={{
                      ...defaultOptions,
                      animationData: quoteAnimation,
                    }}
                  />
                </IconWrapper>
                <Text>
                  Quote in 5minutes, <br /> Claims in 24hours
                </Text>
              </Card>
            </Col>
            <Col md={8} xs={24}>
              <Card>
                <IconWrapper>
                  <Lottie
                    width="100%"
                    options={{
                      ...defaultOptions,
                      animationData: rewardAnimation,
                    }}
                  />{" "}
                </IconWrapper>
                <Text>Access to Rewards</Text>
              </Card>
            </Col>
          </Row>
        </Section>
      </Content>
    </Wrapper>
  );
};

export default Index;
