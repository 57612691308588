import React, { useState } from "react";
import FacebookIcon from "../../assets/images/heirs-icons-facebook.svg";
import InstagramIcon from "../../assets/images/heirs-icons-instagram.svg";
import YoutubeIcon from "../../assets/images/heirs-icons-youtube.svg";
import LinkedInIcon from "../../assets/images/heirs-icons-linkedin.svg";
import { SocialMediaWrapper } from "./style";
import { Mixpanel } from "./../../Mixpanel";
import { Row, Col } from "antd";
import {
  Wrapper,
  ItemWrapper,
  Title,
  ListWrapper,
  ListItemWrapper,
  Text,
  List,
  Video,
} from "./style";
import Lottie from "lottie-react-web";
import animationData from "../../assets/animations/about-us.json";
const defaultOptions = {
  loop: true,
  animationData,
};
const Index = () => {
  const [activeTab, setActiveTab] = useState(0);

  const bold = {
    backgroundColor: "red",
    padding: "10px 20px",
    color: "white",
    borderRadius: "10px",
    overflow: "hidden",
  };
  const itlics = {
    fontWeight: "bold",
    fontSize: "12pt",
  };
  const itlicx = {
    fontSize: "13pt",
  };
  const pstyle = {
    textAlign: "center",
    margin: "0 auto",
    fontSize: "13pt",
  };
  const xstyle = {
    margin: "20px auto",
  };

  return (
    <Wrapper>
      <Row gutter={[16, 0]}>
        <Col lg={12}>
          <img
            src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/09/essay-winner-2023.jpg"
            width="100%"
            options={defaultOptions}
          />
        </Col>
        <Col lg={11} offset={1}>
          <ListWrapper>
            <ListItemWrapper
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              Meet the winner of the 2023 Heirs Life Essay Championship
            </ListItemWrapper>
          </ListWrapper>

          <ItemWrapper active={activeTab === 0}>
            <Text>
              <b>Kentimfon Ndanyongmong</b>, a JSS 3 student of Beulah
              International School, Akwa Ibom has just won the 2023 Heirs Life
              Essay Championship. He will receive the grand prize of N2,000,000
              worth of scholarship, and his school will be granted N500,000 cash
              for library supplies.
            </Text>
            <Text>
              <b style={{ color: "#ff0000" }}>Second Place:</b> Samuel John of
              Knightdale Middle College, Lagos who has won a cash prize of
              N150,000.
            </Text>
            <Text>
              <b style={{ color: "#ff0000" }}>Third Place:</b> Ameenah Jimeta of
              Prime College, Kano who will be rewarded with N100,000 cash.{" "}
            </Text>
            <Text>
              <p>
                For updates on the Heirs Life Essay Championship follow us on
                social media
              </p>
              <SocialMediaWrapper>
                <a
                  href="https://www.facebook.com/heirsinsurancegrou/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={FacebookIcon} alt="facebook" />
                </a>
                <a
                  href="https://www.instagram.com/heirsinsurancegroup/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={InstagramIcon} alt="instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={YoutubeIcon} alt="twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/heirsinsurancegroup/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkedInIcon} alt="linkedin" />
                </a>
              </SocialMediaWrapper>
              <br />
              <div style={itlics}>
                <a href="https://api.whatsapp.com/send?phone=2349122222200">
                  For inquires, chat with us on Whatsapp: 09122222200
                </a>
              </div>
              <br />
              <div>
                <a href="https://www.heirslifeassurance.com/blog">
                  Click here for FAQs
                </a>
              </div>
            </Text>
          </ItemWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Index;
