import React from "react";
import Banner from "./Banner";
import DigitalChannels from "../../components/DigitalChannels";
import backgroundImage from "../../assets/images/digital_HLA.png";

const Index = () => {
  const bannerPayload = {
    title: "We are fully digital",
    descriptions: [
      "Choose how you want to be served from our multiple touch points.",
    ],
    backgroundImage,
    linkPath: "#digitalchannels",
    linkText: "Get Started",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <DigitalChannels />
    </div>
  );
};

export default Index;
