import React, { useEffect, useState } from "react";
import { Text, Title, Wrapper, List } from "../PlanPicker/styles";
import annuitylite from "../../assets/images/annuity-lite.png";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constant";
import { Mixpanel } from "../../Mixpanel";
import { Col, Modal, Form } from "antd";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Wrapper id="annuity">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <img src={annuitylite} alt="annuity lite" />
                </th>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <Title>Annuity Lite</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Heirs Annuity Plan - Buy Now")}
                    href={`${HeirsDashboardUrl}/annuity-option?policy-type=Heirs%20Annuity%20Lite`}
                    style={{ margin: "2px" }}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Heirs Annuity Plan - Get Quote")}
                    href={`${HeirsDashboardUrl}/annuity-option?policy-type=Heirs%20Annuity%20Lite`}
                    style={{ margin: "2px" }}
                  >
                    Get A Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Perfect for?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Anyone</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Age</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>45 and above</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Contribution Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Once (as a single payment)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="2">
                  <Text highlight>Benefits</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Annuity benefit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>A steady amount paid to the client for life.</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Annuity Payment Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Monthly or Quarterly or Semi-annually, or annually
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support to your beneficiary if death occurs within
                    the first 5/10 years of the policy.
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    The surrender value or present value of the balance for the
                    outstanding guaranteed period.
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Documents Required</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>A clear copy of passport photographs (compulsory).</li>
                    <li>
                      One copy of valid means of identification (compulsory).
                    </li>
                    <li>
                      One copy of proof of residence dated in the last six
                      months (compulsory).
                    </li>
                    <li>
                      Evidence of age (if DOB is not provided on the means of
                      identification) (compulsory).
                    </li>
                    <li>Evidence of payment of premium.</li>
                    <li style={{ marginBottom: "10px" }}>
                      Currency Transaction form if premium is more than NGN5m
                      (this will be sent from HLA to you).
                    </li>
                    <i>
                      <b>
                        On the third requirement, the client may use the
                        following:
                      </b>
                    </i>
                    <li>
                      Their utility bill - light, waste disposal, water etc.
                    </li>
                    <li>
                      Tenancy receipt/agreement that bears their residential
                      address.
                    </li>
                    <li>Levy receipt that bears their residential address.</li>
                  </List>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ padding: "0px" }}
      >
        <Col>
          <h1 style={{ color: "rgb(255,0,0)", paddingBottom: "10px" }}>
            Contributory Pension Scheme - FAQs
          </h1>
        </Col>
        <Text
          style={{
            width: "100%",
            height: "500px",
            overflow: "auto",
            fontSize: "11pt",
          }}
        >
          <ol>
            <li style={{ margin: "20px 5px" }}>
              <b>What is Retiree Life Annuity?</b> This is a stream of income
              purchased from a life insurance company with the available RSA
              balance under the CPS as premium. It provides a guaranteed
              periodic income (pension) to a retiree throughout his/her life
              after retirement. Annuity is guaranteed for ten years. If the
              retiree dies within the first ten years of taking up the Heirs
              Annuity plan, the named beneficiary on the policy is paid the
              present value of the balance outstanding in the guaranteed period.
            </li>
            <li style={{ margin: "20px 5px" }}>
              <b>How can Annuity be purchased?</b> A retiree can buy annuity by
              remitting his/her available RSA balance as premium to a Retiree
              Life Annuity Provider (Life Insurance Company) with the commitment
              to provide the monthly/quarterly annuity payments for life.
            </li>
            <li style={{ margin: "20px 5px" }}>
              <b>Who regulates Retiree Life Annuity?</b> The Regulations on RLA
              are jointly issued by the National Insurance Commission (NAICOM)
              and the National Pension Commission (PenCom).
            </li>
            <li style={{ margin: "20px 5px" }}>
              <b>Can I choose PW and later change to RLA?</b> A retiree on PW
              can change to RLA.
            </li>
            <li style={{ margin: "20px 5px" }}>
              <b>How can the change of RLA be effected?</b> A retiree can
              request the PFA to transfer his RSA balance to his chosen Retiree
              Life Annuity Provider (RLA Provider), given the PFA one month
              notice.
            </li>
            <li style={{ margin: "20px 5px" }}>
              <b>Can I change from RLA to PW?</b> A retiree on RLA cannot change
              to PW. This is because RLA is for life.
            </li>
            <li style={{ margin: "20px 5px" }}>
              <b>Can a retiree on RLA change his RLA Provider?</b> A retiree on
              RLA can change his RLA Provider after at least two years of being
              with the current RLA Provider with the approval of NAICOM.
            </li>
            <li style={{ margin: "20px 5px" }}>
              <b>Can RLA be exhausted?</b> The rist having been transferred to
              the RLA provider, RLA cannot be exhausted.
            </li>
            <li style={{ margin: "20px 5px" }}>
              <b>Where can a retiree on RLA take his complaint to?</b> A retiree
              on RLA is expected to take his/her complaint to the RLA provider
              for resolution. However, where the retiree is not satisfied with
              the response of the RLA provider, he/she can seek the intervention
              of NAICOM.
            </li>
            <li style={{ margin: "20px 5px" }}>
              <b>How does lumpsum affect the Annuity payout of a retiree:</b>{" "}
              The higher the lumpsum received the lower the available RSA
              balance for the premium, therefore lower monthly/quarterly annuity
              and vice versa.
            </li>
          </ol>
        </Text>
      </Modal>
    </Wrapper>
  );
};

export default Index;
