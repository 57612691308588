import React from "react";
import Marquee from "react-fast-marquee";
import TestimonialCard from "./TestimonialCard";
import { TestimonialCardWrapper } from "../style";
import MayowaImage from "../../../assets/images/mayowa.jpeg";
import OlamideImage from "../../../assets/images/olamide.jpeg";
import YetundeImage from "../../../assets/images/yetunde.jpeg";
import WoleImage from "../../../assets/images/wole.jpeg";
import NimiImage from "../../../assets/images/nimi.jpeg";

const TestimonialCards = () => {
  const messages = [
    {
      firstName: "Isah Ibrahim",
      lastName: "",
      message:
        "I have subscribed to your platform for 5 months now. So far, so good. Everything is going well.",
      /* avatarUrl: MayowaImage, */
    },
    {
      firstName: "Usiola Victor",
      lastName: "",
      message:
        "I have introduced almost ten staff of my office, Federal High Court, to your team.",
      /* avatarUrl: YetundeImage, */
    },
    {
      firstName: "Anita Njokede",
      lastName: "",
      message:
        "Absolutely amazing service so far. Prompt response always. And the process being automated has helped a lot. Thank you.",
      /* avatarUrl: WoleImage, */
    },
    {
      firstName: "Ishaku Musa",
      lastName: "",
      message:
        "So far, so good. My monthly pension comes as and when due. How you give updates concerning your activities is highly commendable. Keep it up.",
      /* avatarUrl: NimiImage, */
    },
    {
      firstName: "Afolayan Olayinka",
      lastName: "",
      message: "The Annuity policy I have with Heirs is an amazing one.",
      /* avatarUrl: OlamideImage, */
    },
    {
      firstName: "Ahmed Abubakar",
      lastName: "",
      message:
        "Extremely surprised by the speed and accuracy of your response to enquiries.",
      /* avatarUrl: OlamideImage, */
    },
    {
      firstName: "Toluwalope",
      lastName: "",
      message:
        "It has been a pleasant experience. Now I can say I have made the right choice!",
      /* avatarUrl: OlamideImage, */
    },
    {
      firstName: "Odum Patience",
      lastName: "",
      message: "Prompt response to clients at all times.",
      /* avatarUrl: OlamideImage, */
    },
    {
      firstName: "Ebene Emmanuel",
      lastName: "",
      message:
        "Monthly allowance is paid on time and they make retirees feel a sense of belonging.",
      /* avatarUrl: OlamideImage, */
    },
    {
      firstName: "Showole O.",
      lastName: "",
      message:
        "I have been with this insurance group for 2 years and I haven't had any problem. I think I can recommend it to someone else.",
      /* avatarUrl: OlamideImage, */
    },
  ];
  return (
    <TestimonialCardWrapper>
      <Marquee speed={40} pauseOnHover={true}>
        {messages.map(
          ({ firstName, lastName, username, message, avatarUrl }, key) => (
            <TestimonialCard
              firstName={firstName}
              lastName={lastName}
              message={message}
              avatarUrl={avatarUrl}
              key={key}
            />
          )
        )}
      </Marquee>
    </TestimonialCardWrapper>
  );
};

export default TestimonialCards;
