import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Form } from "antd";
import FormInput from "../../components/Input";
import { Select, OptionWrapper } from "../../components/Input";
import Button from "../../components/Button";
import {
  Wrapper,
  Title,
  Text,
  BannerContentWrapper,
  LinkWrapper,
  Overlay,
  Video,
  Content,
} from "./stylebanner";
import { CaretRightFilled } from "@ant-design/icons";

const Banner = ({
  title,
  descriptions = [],
  linkPath,
  productURL,
  linkText,
  children,
  backgroundImage,
  link_Path,
  link_Text,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Overlay>
      <Wrapper>
        {productURL ? (
          <>
            <BannerContentWrapper>
              <Title> {title}</Title>
              {descriptions.map((description, key) => (
                <Text
                  key={key}
                  dangerouslySetInnerHTML={{ __html: `${description}` }}
                ></Text>
              ))}
              {linkText && linkPath && (
                <LinkWrapper>
                  <a href={linkPath}>
                    {linkText} <CaretRightFilled />
                  </a>
                </LinkWrapper>
              )}
              {children}
            </BannerContentWrapper>

            <Video>
              <iframe
                width="100%"
                height="315"
                src={productURL}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Video>
          </>
        ) : (
          <Content backgroundImage={backgroundImage}>
            <BannerContentWrapper>
              <Title> {title}</Title>
              {descriptions.map((description, key) => (
                <Text
                  key={key}
                  dangerouslySetInnerHTML={{ __html: `${description}` }}
                ></Text>
              ))}

              {linkText && linkPath && (
                <LinkWrapper>
                  <a href={linkPath} onClick={showModal}>
                    {linkText} <CaretRightFilled />
                  </a>
                </LinkWrapper>
              )}

              {children}
            </BannerContentWrapper>
          </Content>
        )}
        <Modal
          visible={modalVisible}
          onCancel={handleCancel}
          footer={null}
          style={{ padding: "0px" }}
        >
          <Col>
            <h1 style={{ color: "rgb(255,0,0)", paddingBottom: "10px" }}>
              Subscribe To Our Exclusive Newsletter
            </h1>
          </Col>
          <Row gutter={[32, 16]} style={{ marginTop: "30px" }}>
            <Col span={24} md={12}>
              <FormInput
                placeholder="First name"
                name="firstName"
                type="text"
              />
            </Col>
            <Col span={24} md={12}>
              <FormInput placeholder="Email address" name="email" type="text" />
            </Col>
            <Col span={24} md={12}>
              <FormInput placeholder="Phone number" name="phone" type="text" />
            </Col>
          </Row>
          <Row gutter={[32, 16]} style={{ marginTop: "30px" }}>
            <Col span={24} md={12}>
              <Button
                style={{
                  borderRadius: "10px",
                }} /* onClick={handleSubmit} disabled={isDisabled} */
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Modal>
      </Wrapper>
    </Overlay>
  );
};

export default Banner;
