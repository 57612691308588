import React from "react";
import Banner from "../../components/ProductBanner";
import PlanPicker from "../../components/SavingsPlanPicker";
import backgroundImage from "../../assets/images/Heirsave.png";

const Index = () => {
  const bannerPayload = {
    title: "Heirs Save",
    descriptions: [
      "Heirs Save is the perfect plan to achieve a particular goal.",
      "You earn interests while saving monthly towards your goal. Its advantage is that you can also secure an additional amount as financial support for your family or loved ones if you do not survive the plan. The amount can be secured for as little as 1% of the desired amount.",
      // "This plan works in partnership with schools to secure the education of students",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#save",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <PlanPicker />
    </div>
  );
};

export default Index;
