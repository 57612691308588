import React, { useEffect } from "react";
import { Text, Title, Wrapper } from "../PlanPicker/styles";
import wholeLife from "../../assets/images/hospital-cash.png";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";
import { Link } from "react-router-dom";
import { List } from "../CreditPlanPicker/styles";
import { HeirsDashboardUrl } from "../../utils/constant";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });

  return (
    <Wrapper id="details">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              {/* <tr>
                <th
                  className="ant-table-cell"
                  colspan="1"
                  style={{ textAlign: "center" }}
                >
                  <Title>Hospital Cash</Title>
                  <a
                    onClick={Mixpanel.track("Hospital Cash - Buy Now")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Surgery%20Care%20Plan`}
                  >
                    Buy Now
                  </a>
                  <a
                    onClick={Mixpanel.track("Hospital Cash - Get Quote")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Surgery%20Care%20Plan`}
                    style={{ margin: "2px" }}
                  >
                    Get Quote
                  </a>
                </th>
              </tr> */}
              <tr>
                <th className="ant-table-cell">
                  <img src={wholeLife} alt="annuity" />
                </th>
                <th className="ant-table-cell">
                  <Text>
                    <b>Hospital Cash</b>
                  </Text>
                  <a
                    onClick={Mixpanel.track("Hospital Cash - Buy Now")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Hospital%20Cash%20Basic%20Plan`}
                    style={{ margin: "2px" }}
                  >
                    Buy Now
                  </a>
                  <a
                    onClick={Mixpanel.track("Hospital Cash - Get Quote")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Hospital%20Cash%20Basic%20Plan`}
                    style={{ margin: "2px" }}
                  >
                    Get Quote
                  </a>
                </th>
                {/* <th className="ant-table-cell">
                  <Text>
                    <b>INTERMEDIATE PLAN</b>
                  </Text>
                  <a
                    onClick={Mixpanel.track("Hospital Cash - Buy Now")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Hospital%20Cash%20Intermediate%20Plan`}
                    style={{ margin: "2px" }}
                  >
                    Buy Now
                  </a>
                  <a
                    onClick={Mixpanel.track("Hospital Cash - Get Quote")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Hospital%20Cash%20Intermediate%20Plan`}
                    style={{ margin: "2px" }}
                  >
                    Get Quote
                  </a>
                </th>
                <th className="ant-table-cell">
                  <Text>
                    <b>ADVANCED PLAN</b>
                  </Text>
                  <a
                    onClick={Mixpanel.track("Hospital Cash - Buy Now")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Hospital%20Cash%20Advance%20Plan`}
                    style={{ margin: "2px" }}
                  >
                    Buy Now
                  </a>
                  <a
                    onClick={Mixpanel.track("Hospital Cash - Get Quote")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Hospital%20Cash%20Advance%20Plan`}
                    style={{ margin: "2px" }}
                  >
                    Get Quote
                  </a>
                </th> */}
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {/* <tr>
                <td className="ant-table-cell" colspan="4">
                  <Text>
                    <b>How It Works</b>
                    <List>
                      <li>
                        You take the hospital cash plan by paying a premium.
                      </li>
                      <li>
                        If you fall sick and get hospitalized for a minimum of 3
                        days, we pay you a bulk sum (your chosen amount).
                      </li>
                      <li>
                        We also pay your beneficiary a bulk amount if loss of
                        life occurs.
                      </li>
                    </List>
                  </Text>
                </td>
              </tr> */}
              <tr>
                <td className="ant-table-cell">
                  <Text>For Whom?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Everyone</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Everyone</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Everyone</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Age Limit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>18 - 64 yrs</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>18 - 64 yrs</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>18 - 64 yrs</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost of Insurance</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Minimum of N600</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>From N1500 / year</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>From N2,800 / year</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Hospital Treatment Benefit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Min: N 20,000</Text>
                  <Text>Max: N200,000</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Min: N50,000</Text>
                  <Text>Max: N100,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Min: N100,000</Text>
                  <Text>Max: 200,000</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Life Insurance{" "}
                    <span style={{ fontSize: "8pt" }}>
                      (Financial support provided to your beneficiary if there's
                      loss of life)
                    </span>
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Min: N 100,000</Text>
                  <Text>Max: N1 Million</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Min: N200,000</Text>
                  <Text>Max: N500,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Min: N500,000</Text>
                  <Text>Max: N1 Million</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Payment Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Annually</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Annually</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Annually</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Waiting Period{" "}
                    <span style={{ fontSize: "8pt" }}>
                      (Period to wait before claims can be made)
                    </span>
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>15 days after taking the policy.</li>
                    <li>Minimum of 3 nights after hospitalization.</li>
                  </List>
                </td>
                {/* <td className="ant-table-cell">
                  <List>
                    <li>15 days after taking the policy</li>
                    <li>Minimum of 3 nights after hospitalization.</li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>15 days after taking the policy.</li>
                    <li>Minimum of 3 nights after hospitalization.</li>
                  </List>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Duration of Cover</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>1 year</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>1 year</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>1 year</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Exclusion</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>Pre-existing conditions.</li>
                    <li>
                      Hospitalisation for pregnancy, maternity, and
                      fertility-related treatments.
                    </li>
                    <li>
                      Cosmetic treatments, plastic surgery, refractive error
                      corrective procedures, experimental, investigational, or
                      unproven procedures or treatments.
                    </li>
                    <li>Hospitalization for self-inflicted injuries.</li>
                  </List>
                </td>
                {/* <td className="ant-table-cell">
                  <List>
                    <li>Pre-existing conditions.</li>
                    <li>
                      Hospitalisation for pregnancy, maternity, and
                      fertility-related treatments.
                    </li>
                    <li>
                      Cosmetic treatments, plastic surgery, refractive error
                      corrective procedures, experimental, investigational, or
                      unproven procedures or treatments.
                    </li>
                    <li>Hospitalization for self-inflicted injuries.</li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>Pre-existing conditions.</li>
                    <li>
                      Hospitalisation for pregnancy, maternity, and
                      fertility-related treatments.
                    </li>
                    <li>
                      Cosmetic treatments, plastic surgery, refractive error
                      corrective procedures, experimental, investigational, or
                      unproven procedures or treatments.
                    </li>
                    <li>Hospitalization for self-inflicted injuries.</li>
                  </List>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="2">
                  <Text>
                    <b>CLAIM PROCESS: </b>
                    <br />
                    Submit doctor's report via our{" "}
                    <a href="/claims" class="nona">
                      Fast Claims Portal
                    </a>{" "}
                    or email directly to{" "}
                    <span class="red">wecare@heirslifeassurance.com</span>{" "}
                    <br />
                    For emergencies, we will pay the claim immediately to save
                    the life and investigate afterward.
                  </Text>
                </td>
              </tr>
              {/* <tr>
                <td className="ant-table-cell" colspan="4">
                  <Text>
                    <b>Other Products of Interest</b>
                    <br />
                    You may also be interested in the following products from
                    Heirs Life:
                  </Text>
                  <List>
                    <li>
                      <a
                        href="/heirs-save"
                        style={{ background: "none", color: "black" }}
                      >
                        Heirs Save Plan
                      </a>{" "}
                      From N5,000/month.
                    </li>
                    <li>
                      <a
                        href="/triple-pay"
                        style={{ background: "none", color: "black" }}
                      >
                        Triple Pay Plan
                      </a>{" "}
                      (long-term savings plan with 3 bulk pay-outs).
                    </li>
                    <li>
                      <a
                        href="/surgery"
                        style={{ background: "none", color: "black" }}
                      >
                        Heirs Surgery Care Plan
                      </a>{" "}
                      (for unexpected medical surgeries).
                    </li>
                  </List>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
