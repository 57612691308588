import React from "react";
import Banner from "../../components/ProductBanner";
import SalaryPlanPicker from "../../components/SalaryPlanPicker";
import backgroundImage from "../../assets/images/salary.png";

const Index = () => {
  const bannerPayload = {
    title: "Salary Plus Plan",
    descriptions: [
      "This plan is perfect for salary earners who want to earn competitive returns while saving towards future goals or projects. Automate the deduction of a steady amount from your salary which will earn interests over the desired period.",
      "You can secure an additional amount as financial support for your family, if you do not survive the plan. The amount can be secured for as little as 1% of the desired amount.",
      "If the unexpected happens to you, Heirs Life pays your beneficiary the agreed amount, plus your total savings plus interest. If you survive the plan, you get all your savings plus interest.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#salary",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <SalaryPlanPicker />
    </div>
  );
};

export default Index;
