import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  color: ${Colors.TEXT_DEFAULT};
  margin-bottom: 0;

  @media screen and (max-width: 991px) {
    font-size: 35px;
    line-height: 35px;
  }

  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media screen and (max-width: 576px) {
    font-size: 18px;
    line-height: 17px;
  }
`;

export const Paragraph = styled("div")`
  margin-bottom: 20px;

  strong {
    font-family: "Mulish Bold", sans-serif;
  }

  ul {
    list-style: initial;
    margin-left: 30px;
  }

  li {
    font-size: 17px;
    margin-bottom: 10px;
  }
`;

export const Heading = styled("h2")`
  font-size: 20px;
  margin-bottom: 5px;
  color: ${Colors.PRIMARY};
`;

export const Text = styled("p")`
  color: ${Colors.TEXT_DEFAULT};
  font-size: 17px;
  margin-bottom: 15px;

  iframe {
    width: 100%;
    height: 30rem;
  }
`;

export const BannerContentWrapper = styled("div")`
  ${containerWrapper};
`;

export const PageContentWrapper = styled("div")`
  ${containerWrapper};
  margin: 50px auto;

  @media screen and (max-width: 991px) {
    margin: 30px auto;
  }
`;

export const Wrapper = styled("div")`
  margin-top: 4.4%;
  min-height: 200px;
  background-color: rgb(243 84 91 / 7%);

  display: flex;
  align-items: center;

  @media screen and (max-width: 991px) {
    min-height: 100px;
  }

  @media screen and (max-width: 767px) {
    min-height: 80px;
  }

  @media screen and (max-width: 575px) {
    min-height: 65px;
  }
`;
