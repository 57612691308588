import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/surgery-care.png";
import SurgeryPlanPicker from "../../components/SurgeryPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Surgery Care Plan",
    descriptions: [
      "We do not hope for medical emergencies, but they do happen.",
      "In the case that you need medical surgery, this plan covers the expenses of such unexpected surgeries, ensuring that you get help exactly when you need it.",
      "The affordable Heirs Surgery Care plan helps to pay medical bills for illnesses requiring surgery such as cancer, heart failure, kidney failure, stroke, appendicitis, surgeries relating to accidents, and other related medical conditions.",
      "With this plan, you do not need to go bankrupt to get your life back.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#surgery",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <SurgeryPlanPicker />
    </div>
  );
};

export default Index;
