import React from "react";
import Banner from "./Banner";
import backgroundImage from "../../assets/images/kid-essay.png";
import { Wrapper } from "./styles";
import Content from "./content";
import { List } from "../../components/CreditPlanPicker/styles";
import appleStore from "../../assets/images/appstore.png";
import googlePlay from "../../assets/images/playstore.png";
import FacebookIcon from "../../assets/images/heirs-icons-facebook.svg";
import InstagramIcon from "../../assets/images/heirs-icons-instagram.svg";
import YoutubeIcon from "../../assets/images/heirs-icons-youtube.svg";
import LinkedInIcon from "../../assets/images/heirs-icons-linkedin.svg";
import { SocialMediaWrapper } from "../../components/Navigation/Footer/styles";
import { Mixpanel } from "../../Mixpanel";
import { AutoComplete } from "antd";

const Index = () => {
  const bannerPayload = {
    title: "Secure Up to 10M Naira for Your Child's Future Ambition",
    children: "",
    details:
      "Join over 7,000 parents currently subscribed to our MyHeirs plan.",
    text: "Coming Soon...",
    backgroundImage,
  };
  const mystyle = {
    backgroundColor: "white",
    padding: "45px 30px",
    fontFamily: "Arial",
  };
  const bold = {
    backgroundColor: "red",
    padding: "10px 20px",
    color: "white",
    borderRadius: "10px",
    overflow: "hidden",
  };
  const itlics = {
    fontWeight: "bold",
  };
  const itlicx = {
    fontSize: "13pt",
  };
  const lu = {
    padding: "10px",
  };
  const pad = {
    paddingButtom: "10px",
  };
  const dvstyle = {
    padding: "10px",
    color: "#fff",
    backgroundColor: "rgb(255,0,0)",
    borderRadius: "10px",
    margin: "0 auto",
    textAlign: "center",
    width: "70%",
    fontSize: "13pt",
    fontWeight: "bold",
  };
  const pstyle = {
    textAlign: "center",
    margin: "0 auto",
    fontSize: "13pt",
  };
  const xstyle = {
    margin: "20px auto",
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />
      <Content />
    </Wrapper>
  );
};

export default Index;
