import React, { useEffect } from "react";
import { Text, Title, Wrapper } from "../PlanPicker/styles";
import wholeLife from "../../assets/images/surgery-care.png";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";
import { Link } from "react-router-dom";
import { List } from "../CreditPlanPicker/styles";
import { HeirsDashboardUrl } from "../../utils/constant";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });

  return (
    <Wrapper id="surgery">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <img src={wholeLife} alt="annuity" />
                </th>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <Title>Heirs Surgery Care</Title>
                  <a
                    onClick={Mixpanel.track("Heirs Surgery Care - Buy Now")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Surgery%20Care%20Plan`}
                  >
                    Buy Now
                  </a>
                  <a
                    onClick={Mixpanel.track("Heirs Surgery Care - Get Quote")}
                    href={`${HeirsDashboardUrl}/health-type?policy-type=Heirs%20Surgery%20Care%20Plan`}
                    style={{ margin: "2px" }}
                  >
                    Get Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>For Whom?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Everyone</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Age Limit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>18 - 55 years</Text>
                  <Text>(The cover ceases at 60 years)*</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Cover for medical surgery</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>To be chosen by you (up to &#8358;50,000,000)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support for funeral expense if there is loss of
                    life
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to &#8358;2,000,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Extra Benefit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    <b>Free Medical Checkup:</b>
                  </Text>
                  <List>
                    <li>
                      Every 3 years, you are entitled to free medical checkup,
                      paid by Heirs Life (up to the cost of 1% of your sum
                      assured and with a limit of &#8358;200,000).{" "}
                    </li>
                    <li>
                      If there is need for surgery after the checkup, this cost
                      will be deducted from the cost of the surgery.
                    </li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="2">
                  <Text highlight>Other Details</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost of insurance</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Calculated based on your age:</Text>
                  <List>
                    <li>
                      <b>18 - 25 years:</b> Cost is 1.87% of the chosen medical
                      cover
                    </li>
                    <li>
                      <b>26 - 40 years:</b> Cost is 2.01% of the chosen medical
                      cover
                    </li>
                    <li>
                      <b>41 - 50 years:</b> Cost is 2.16% of the chosen medical
                      cover
                    </li>
                    <li>
                      <b>51 - 60 years:</b> Cost is 2.33% of the chosen medical
                      cover
                    </li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Payment Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Annually</Text>
                </td>
              </tr>
              {/* <tr>
                <td className="ant-table-cell">
                  <Text>Minimum Duration</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>5 years</Text>
                </td>
              </tr> */}
              <tr>
                <td className="ant-table-cell">
                  <Text>Waiting Period (Applies to illness only)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>6 months</Text>
                  <Text>Medical examination is not required*</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Surgeries Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>Cancer</li>
                    <li>Heart failure</li>
                    <li>Kidney failure</li>
                    <li>Stroke</li>
                    <li>Appendicitis</li>
                    <li>Surgeries relating to accidents</li>
                    <li>Other related diseases*</li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Exclusion (surgeries not covered)</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>Maternity related surgeries</li>
                    <li>Cosmetic Surgery</li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Plan terminates if:</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>You discontinue your plan</li>
                    <li>You fail to renew your payment after 30 days</li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="2">
                  <Text>
                    <b>CLAIM PROCESS: </b>
                    <br />
                    Submit doctor's report via our{" "}
                    <a href="/claims" class="nona">
                      Fast Claims Portal
                    </a>{" "}
                    or email directly to{" "}
                    <span class="red">wecare@heirslifeassurance.com</span>{" "}
                    <br />
                    For emergencies, we will pay the claim immediately to save
                    the life and investigate afterward.
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
