import React, { useEffect, useState } from "react";
import { Row, Col, Checkbox, Modal } from "antd";
import { FormContentWrapper, FormWrapper, Title } from "./styles";
import FormInput from "../../components/Input";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { HeirsEmailUrl } from "../../utils/constant";

const Form = () => {
  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    plan: "SalaryPlus",
    destination: "nimiweremi.selekere@heirslifeassurance.com",
  });
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [isChecked, setChecked] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  let history = useHistory();

  useEffect(() => {
    if (
      isChecked &&
      value.firstName &&
      value.lastName &&
      value.email &&
      value.phoneNumber &&
      !error.firstName &&
      !error.lastName &&
      !error.email &&
      !error.phoneNumber
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [value, error, isChecked]);

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: "" },
      });
      Modal.success({
        content: msg,
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
      Array.from(document.querySelectorAll("textarea")).forEach(
        (textarea) => (textarea.value = "")
      );
      setIsDisabled(false);
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleSubmit = (e) => {
    const data = { ...value };
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: `${HeirsEmailUrl}/email/others`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          handleServerResponse(
            true,
            "Thank you, you will be redirected to the product page shortly"
          );
          // handleServerResponse(true, response.data.message);
          setTimeout(() => history.push("/salary"), 5000);
        } else {
          handleServerResponse(false, response.data.message);
        }
      })
      .catch((error) => {
        handleServerResponse(false, "An internal error occured");
      });
  };

  // eslint-disable-next-line no-unused-vars
  const handleChange = (e) => {
    const val = e.target.value;
    setValue({
      ...value,
      message: val,
    });

    if (val.length === 0) {
      setError({ ...error, message: "message cannot be blank" });
    } else {
      setError({ ...error, message: "" });
    }
  };

  const handleNameChange = (e) => {
    const val = e.target.value;
    if (!/[^a-zA-Z]/.test(val)) {
      setValue({
        ...value,
        [e.target.name]: val,
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: "",
      });
    }

    if (val.length < 3) {
      setError({
        ...error,
        [e.target.name]: "name cannot be less than three characters",
      });
    } else {
      setError({
        ...error,
        [e.target.name]: "",
      });
    }
  };

  const handleEmailChange = (e) => {
    const reg = /\S+@\S+\.\S+/;
    const val = e.target.value;
    setValue({ ...value, email: val });

    if (reg.test(String(val).toLowerCase())) {
      setError({ ...error, email: "" });
    } else {
      setError({
        ...error,
        email: "please enter a valid email address",
      });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const val = e.target.value;
    const reg = /^[0-9]+$/;

    if (reg.test(val)) {
      setValue({ ...value, phoneNumber: val });
    }

    if (val.length < 11 || val.length > 11) {
      setError({
        ...error,
        phoneNumber:
          "your phone number should not be more or less than 11 digits",
      });
    } else {
      setError({
        ...error,
        phoneNumber: "",
      });
    }
  };

  return (
    <FormWrapper>
      <FormContentWrapper>
        <Title>Heirs Salary Plus Plan </Title>
        <p>
          Hey there! Are you a Salary earner? Do you want to save towards a
          particular goal and earn competitive interests while saving? <br />
          Then the Heirs Salary Plus plan is perfect for you. You get to
          automate the deduction of a steady amount from your salary which will
          earn interests over the desired period.
          <br /> Kindly fill out your information below and we will get back to
          you{" "}
        </p>
        <Row gutter={[32, 16]}>
          <Col span={24} md={12}>
            <FormInput
              placeholder="First name"
              name="firstName"
              type="text"
              onChange={handleNameChange}
              value={value.firstName}
            />
            <small>{error.firstName}</small>
          </Col>
          <Col span={24} md={12}>
            <FormInput
              placeholder="Last name"
              name="lastName"
              type="text"
              minLength={3}
              onChange={handleNameChange}
              value={value.lastName}
            />
            <small>{error.lastName}</small>
          </Col>
          <Col span={24} md={12}>
            <FormInput
              placeholder="Email Address"
              name="email"
              type="email"
              onChange={handleEmailChange}
              value={value.email}
            />
            <small>{error.email}</small>
          </Col>
          <Col span={24} md={12}>
            <FormInput
              placeholder="Phone number"
              name="phoneNumber"
              type="text"
              onChange={handlePhoneNumberChange}
              value={value.phoneNumber}
            />
            <small>{error.phoneNumber}</small>
          </Col>
          {/* <Col span={24}>
            <textarea
              rows={4}
              placeholder="Leave a message"
              name="message"
              onChange={handleChange}
              value={value.message}
            />
            <small>{error.message}</small>
          </Col> */}
          <Col span={24}>
            <Checkbox
              checked={isChecked}
              onChange={() => setChecked(!isChecked)}
            >
              I agree to the company’s{" "}
              <Link to="/terms">Terms and Conditions</Link>
            </Checkbox>
          </Col>
        </Row>
      </FormContentWrapper>
      <Button type="submit" onClick={handleSubmit} disabled={isDisabled}>
        Subscribe
      </Button>
      {status.info.error && (
        <div className="error">Error: {status.info.msg}</div>
      )}
      {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
    </FormWrapper>
  );
};

export default Form;
