import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/yermassure.png";
import AssurePlanPicker from "../../components/AssurePlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Term Life",
    descriptions: [
      "No one can predict tomorrow, but if your family depends on your income, then you need this plan so they can live conveniently.",
      "The Term Life plan guarantees income replacement for your family if you are no more, so that your loved ones can carry on with life without hardship. With as little as 1% of the amount you want us to pay your family, you can secure finances for your family during periods of loss.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#assure",
    productURL:
      "https://www.youtube.com/embed/ftXvApYOt-k?list=PLKzX6UG7Pf2kTUGTjEUfl1J79w2wVr0Gl&rel=0&controls=0",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <AssurePlanPicker />
    </div>
  );
};

export default Index;
