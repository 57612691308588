import React from "react";
import { Wrapper, Title, BannerContentWrapper, BannerContent } from "./styles";
import { Text } from "../../components/ProductBanner/styles";
const Banner = ({ title, backgroundImage, descriptions }) => {
  return (
    <Wrapper backgroundImage={backgroundImage}>
      <BannerContentWrapper>
        <BannerContent>
          <Title> {title}</Title>
          {descriptions.map((description, key) => (
            <Text key={key}>{description}</Text>
          ))}
        </BannerContent>
      </BannerContentWrapper>
    </Wrapper>
  );
};

export default Banner;
