import React, { useEffect } from "react";
import { Text, Title, Wrapper } from "../PlanPicker/styles";
import hat from "../../assets/images/heirs-salary.png";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constant";
import { List } from "../CreditPlanPicker/styles";
import { Mixpanel } from "./../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="salary">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <img src={hat} alt="salary" />
                </th>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <Title>Salary Plus</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Salary Plus - Buy Now")}
                    href={`${HeirsDashboardUrl}/savings-type?policy-type=Heirs Salary plus`}
                    style={{ margin: "2px" }}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Salary Plus - Get Quote")}
                    href={`${HeirsDashboardUrl}/savings-type?policy-type=Heirs Salary plus`}
                    style={{ margin: "2px" }}
                  >
                    Get A Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>For Who</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>For regular salary earners</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Age Limit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>18 - 60 years</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Contribution</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>From N5,000/monthly</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Contribution Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Monthly, quarterly, half-yearly, yearly, single.</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Benefits</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Your accumulated savings plus interest.</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Interest</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    The current bank savings interest rate plus 0.25% per annum
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Payment to beneficiary if there's loss of life</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N2,000,000 plus total savings plus interest</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost of Insurance</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    1% of amount to be earned by your beneficiary if death
                    occurs
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Minimum Duration</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>2 years</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Withdrawal Terms</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Part withdrawal of up to 60% of total savings.</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Medical Examination</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not required</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Account statement</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Request online, USSD and on mobile.</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Documents Required</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>Duly Filled Proposal Form.</li>
                    <li>Evidence of Payment.</li>
                    <li>
                      Valid means of ID - Driver license, Voter's card, NIMC,
                      National ID, International Passport.
                    </li>
                    <li>
                      Proof of residence - Utility Bill, Front page of the
                      customer's address (same address as indicated on the form
                      or portal), Local government levy.
                    </li>
                    <li>
                      Duly Filled CTR, Financial Questionnaire, Address
                      Verification Form, Bank Statement not more than 6-months
                      (if premium is more than N5 million).
                    </li>
                  </List>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
