import React from "react";
import {
  Wrapper,
  Title,
  Text,
  LinkWrapper,
  BannerContentWrapper,
  Content,
} from "./bannerStyle";

import { CaretRightFilled } from "@ant-design/icons";
const Banner = ({
  title,
  linkText,
  linkText2,
  linkPath,
  linkPath2,
  descriptions = [],
  backgroundImage,
  children,
}) => {
  return (
    <Content backgroundImage={backgroundImage}>
      <Wrapper>
        <BannerContentWrapper>
          <Title> {title}</Title>
          {descriptions.map((description, key) => (
            <Text
              key={key}
              dangerouslySetInnerHTML={{ __html: `${description}` }}
            ></Text>
          ))}
          {linkText && linkPath && (
            <LinkWrapper>
              <a href={linkPath2} target="_blank" rel="noreferrer">
                {linkText2} <CaretRightFilled />
              </a>
              <a href={linkPath}>
                {linkText} <CaretRightFilled />
              </a>
            </LinkWrapper>
          )}
          {children}
        </BannerContentWrapper>
      </Wrapper>
    </Content>
  );
};

export default Banner;
