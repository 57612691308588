import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/creditlife.png";
import CreditPlanPicker from "../../components/CreditPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Credit Life",
    descriptions: [
      "The Heirs Credit Life plan can be taken by lending organisations and loanees as a form of loan collateral.",
      "In the case that the debtor is disabled or does not survive the complete payment of the loan, Heirs Life steps in and pays off the outstanding balance to the lending organisation, so that business continues uninterrupted and the debtor's family is shielded from the financial burden of an unexpected loan.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#credit",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <CreditPlanPicker />
    </div>
  );
};

export default Index;
