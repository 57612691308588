import React from "react";
import Banner from "../../components/ProductBanner";
import EntrepreneurPlanPicker from "../../components/EntrepreneurPlanPicker";
import backgroundImage from "../../assets/images/Entrepreneur.png";

const Index = () => {
  const bannerPayload = {
    title: "Entrepreneur Plan",
    descriptions: [
      "Running a successful business takes time, effort and money.",
      "The Heirs Entrepreneurs plan is perfect for small businessowners who want to save towards expanding their business and earn competitive interests on their savings.",
      "This plan also comes bundled with a life insurance benefit which provides financial support to your beneficiary, if something drastic happens to you before the end of the plan.",
      "Another added benefit is that business owners can apply for a loan after 1 year of active savings.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#entrepreneur",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <EntrepreneurPlanPicker />
    </div>
  );
};

export default Index;
