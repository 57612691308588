import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { HeirsFetchPostsUrl } from "../utils/constant";

const useClaims = () => {
  const [value, setValue] = useState({
    accountName: "",
    bankName: "",
    insuredFirstName: "",
    insuredLastName: "",
    policyNumber: "",
    accountNumber: "",
    phoneNumber: "",
    emailAddress: "",
    homeAddress: "",
    claimType: "",
    deathType: "",
    accountDetails: "",
    incidentDate: "",
    incidentTime: "",
    description: "",
    deathCertificate: [],
    medicalBill: [],
    medicalCertificate: [],
    medicalReport: [],
    policeReport: [],
    beneficiaryID: [],
    trueClaims: false,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    accountName: "",
    bankName: "",
    insuredName: "",
    policyNumber: "",
    phoneNumber: "",
    accountNumber: "",
    emailAddress: "",
    homeAddress: "",
    claimType: "",
    deathType: "",
    accountDetails: "",
    incidentDate: "",
    incidentTime: "",
    description: "",
    deathCertificate: "",
    medicalBill: "",
    medicalCertificate: "",
    medicalReport: "",
    policeReport: "",
    beneficiaryID: "",
    trueClaims: "",
    submitError: "",
  });

  useEffect(() => {
    if (
      (value.insuredFirstName &&
        value.insuredLastName &&
        value.phoneNumber &&
        value.emailAddress &&
        value.homeAddress &&
        value.claimType &&
        (value.claimType === "maturity" || value.claimType === "loan") &&
        value.accountNumber &&
        value.accountName &&
        value.bankName &&
        !error.insuredName &&
        !error.phoneNumber &&
        !error.emailAddress &&
        !error.homeAddress &&
        !error.claimType &&
        !error.accountName &&
        !error.accountNumber &&
        !error.bankName) ||
      (value.insuredFirstName &&
        value.insuredLastName &&
        value.phoneNumber &&
        value.emailAddress &&
        value.homeAddress &&
        value.claimType &&
        value.claimType === "surrender/path-withdrawal" &&
        value.accountName &&
        value.accountNumber &&
        value.bankName &&
        !error.insuredName &&
        !error.phoneNumber &&
        !error.emailAddress &&
        !error.homeAddress &&
        !error.claimType &&
        !error.accountName &&
        !error.accountNumber &&
        !error.bankName) ||
      (value.insuredFirstName &&
        value.insuredLastName &&
        value.policyNumber &&
        value.phoneNumber &&
        value.emailAddress &&
        value.homeAddress &&
        value.claimType &&
        value.claimType === "death" &&
        value.deathType &&
        value.deathType === "accident" &&
        value.incidentDate &&
        // value.description &&
        value.policeReport.length > 0 &&
        value.trueClaims &&
        !error.insuredName &&
        !error.policyNumber &&
        !error.phoneNumber &&
        !error.emailAddress &&
        !error.homeAddress &&
        !error.claimType &&
        !error.deathType &&
        !error.incidentDate &&
        // !error.description &&
        !error.deathCertificate &&
        !error.medicalCertificate &&
        !error.beneficiaryID &&
        !error.trueClaims) ||
      (value.insuredFirstName &&
        value.insuredLastName &&
        value.policyNumber &&
        value.phoneNumber &&
        value.emailAddress &&
        value.homeAddress &&
        value.claimType &&
        value.claimType === "death" &&
        value.deathType &&
        value.deathType === "natural" &&
        value.incidentDate &&
        // value.description &&
        value.trueClaims &&
        !error.insuredName &&
        !error.policyNumber &&
        !error.phoneNumber &&
        !error.emailAddress &&
        !error.homeAddress &&
        !error.claimType &&
        !error.deathType &&
        !error.incidentDate &&
        !error.description &&
        // !error.deathCertificate.length > 0 &&
        !error.medicalCertificate.length > 0 &&
        !error.policeReport.length > 0 &&
        !error.beneficiaryID &&
        !error.trueClaims) ||
      (value.insuredFirstName &&
        value.insuredLastName &&
        value.policyNumber &&
        value.phoneNumber &&
        value.emailAddress &&
        value.homeAddress &&
        value.claimType &&
        (value.claimType === "disablement" ||
          value.claimType === "critical illness") &&
        // value.description &&
        value.trueClaims &&
        !error.insuredName &&
        !error.policyNumber &&
        !error.phoneNumber &&
        !error.emailAddress &&
        !error.homeAddress &&
        !error.claimType &&
        !error.description &&
        !error.medicalReport &&
        !error.medicalBill &&
        !error.trueClaims) ||
      (value.insuredFirstName &&
        value.insuredLastName &&
        value.policyNumber &&
        value.phoneNumber &&
        value.emailAddress &&
        value.homeAddress &&
        value.claimType &&
        // value.description &&
        value.claimType === "loss of job" &&
        !error.insuredName &&
        !error.policyNumber &&
        !error.phoneNumber &&
        !error.emailAddress &&
        !error.homeAddress &&
        !error.claimType)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [value, error]);

  const handleInsuredNameChange = (e) => {
    const val = e.target.value;
    setValue({
      ...value,
      [e.target.name]: val,
    });

    if (val.length < 3) {
      setError({
        ...error,
        insuredName: "name cannot be less than three characters",
      });
    } else {
      setError({
        ...error,
        insuredName: "",
      });
    }
    if (val && !/^[a-zA-Z]+$/.test(val)) {
      setError({ ...error, insuredName: "name should only be letters" });
    } else {
      setError({
        ...error,
        insuredName: "",
      });
    }
  };

  const handleNumberChange = (e, callback) => {
    const val = e.target.value;
    const reg = /^[0-9]+$/;
    callback(e, reg, val);
  };

  //   const handleOtherNumbersChange = (e, reg, val) => {
  //     if (reg.test(val)) {
  //       setValue({ ...value, [e.target.name]: val });
  //       setError({ ...error, [e.target.name]: "" });
  //     }
  //     if (!reg.test(val)) {
  //       setError({ ...error, [e.target.name]: "values can only be numbers" });
  //     }
  //   };

  const handlePhoneNumberChange = (e, reg, val) => {
    if (reg.test(val)) {
      setValue({ ...value, phoneNumber: val });
    }

    if ((val.length > 0 && val.length < 11) || val.length > 11) {
      setError({
        ...error,
        phoneNumber:
          "your phone number should not be more or less than 11 digits",
      });
    } else {
      setError({
        ...error,
        phoneNumber: "",
      });
    }
  };

  const handleAccountNumberChange = (e, reg, val) => {
    if (reg.test(val)) {
      setValue({ ...value, accountNumber: val });
    }

    if ((val.length > 0 && val.length < 10) || val.length > 10) {
      setError({
        ...error,
        accountNumber:
          "your account number should not be more or less than 10 digits",
      });
    } else {
      setError({
        ...error,
        accountNumber: "",
      });
    }
  };
  const handleEmailChange = (e) => {
    const reg = /\S+@\S+\.\S+/;
    const val = e.target.value;
    setValue({ ...value, emailAddress: val });

    if (reg.test(String(val).toLowerCase())) {
      setError({ ...error, emailAddress: "" });
    } else {
      setError({
        ...error,
        emailAddress: "please enter a valid email address",
      });
    }
  };

  const handleAccountNamesChange = (e) => {
    const val = e.target.value;
    setValue({ ...value, [e.target.name]: val });
    if (val.length === 0 || val.length < 2) {
      setError({
        ...error,
        [e.target.name]: "Please enter valid information",
      });
    } else {
      setError({
        ...error,
        [e.target.name]: "",
      });
    }
  };

  const handleLocationChange = (e) => {
    const val = e.target.value;
    setValue({ ...value, homeAddress: val });
  };

  const handleClaimSelectChange = (e) => {
    if (e === "maturity") {
      setValue({ ...value, claimType: e, trueClaims: true });
    } else {
      setValue({ ...value, claimType: e, trueClaims: false });
    }
  };

  const handleDeathSelectChange = (e) => {
    setValue({ ...value, deathType: e });
  };

  function handleDateChange(date, dateString) {
    setValue({ ...value, incidentDate: dateString });
  }

  //   function handleTimeChange(time, timeString) {
  //     setValue({ ...value, incidentTime: timeString });
  //   }

  const handleDescriptionChange = (e) => {
    const val = e.target.value;
    setValue({ ...value, description: val });
  };

  const handleUploadChange = (e) => {
    console.log(e);
  };

  const onDeathCertificateUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 1000000) {
      setError({ ...error, deathCertificate: "file size is more than 1MB" });
    } else {
      setError({ ...error, deathCertificate: "" });
    }
    return e && e.fileList;
  };

  const onMedicalCertificateUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 1000000) {
      setError({ ...error, medicalCertificate: "file size is more than 1MB" });
    } else {
      setError({ ...error, medicalCertificate: "" });
    }
    return e && e.fileList;
  };

  const onMedicalBillUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 1000000) {
      setError({ ...error, medicalBill: "file size is more than 1MB" });
    } else {
      setError({ ...error, medicalBill: "" });
    }
    return e && e.fileList;
  };

  const onMedicalReportUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 1000000) {
      setError({ ...error, medicalReport: "file size is more than 1MB" });
    } else {
      setError({ ...error, medicalReport: "" });
    }
    return e && e.fileList;
  };

  const onPoliceReportUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 1000000) {
      setError({ ...error, policeReport: "file size is more than 1MB" });
    } else {
      setError({ ...error, policeReport: "" });
    }
    return e && e.fileList;
  };

  const onBeneficiaryIDUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 2000000) {
      setError({ ...error, beneficiaryID: "file size is more than 2MB" });
    } else {
      setError({ ...error, beneficiaryID: "" });
    }
    return e && e.fileList;
  };

  const handleCheckedChange = (e) => {
    const val = e.target.checked;
    setValue({ ...value, [e.target.id]: val });
  };

  const handleDeathFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 1000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/life/single`,
        data: formData,
      })
        .then((response) => {
          setValue({
            ...value,
            deathCertificate: [
              ...value.deathCertificate,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
              ,
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        deathCertificate: "File size is larger than 1MB",
      });
      data.onSuccess("ok");
    }
  };

  const handleMedicalFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 1000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/life/single`,
        data: formData,
      })
        .then((response) => {
          setValue({
            ...value,
            medicalCertificate: [
              ...value.medicalCertificate,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
              ,
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        medicalCertificate: "File size is larger than 1MB",
      });
      data.onSuccess("ok");
    }
  };

  const handleMedicalBillFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 1000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/life/single`,
        data: formData,
      })
        .then((response) => {
          setValue({
            ...value,
            medicalBill: [
              ...value.medicalBill,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        medicalBill: "File size is larger than 1MB",
      });
      data.onSuccess("ok");
    }
  };

  const handleMedicalReportFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 1000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/life/single`,
        data: formData,
      })
        .then((response) => {
          setValue({
            ...value,
            medicalReport: [
              ...value.medicalReport,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        medicalReport: "File size is larger than 1MB",
      });
      data.onSuccess("ok");
    }
  };

  const handlePoliceFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 1000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/life/single`,
        data: formData,
      })
        .then((response) => {
          setValue({
            ...value,
            policeReport: [
              ...value.policeReport,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        policeReport: "File size is larger than 1MB",
      });
      data.onSuccess("ok");
    }
  };

  const handlePolicyNumberChange = (e) => {
    const val = e.target.value;
    const reg = /^[0-9]+$/;

    if (val === "" || reg.test(val)) {
      setValue({ ...value, policyNumber: val });
      setError({ ...error, policyNumber: "" });
    } else {
      setError({ ...error, policyNumber: "please enter only numbers" });
    }
  };

  const handleBeneficiaryFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 2000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/life/single`,
        data: formData,
      })
        .then((response) => {
          setValue({
            ...value,
            beneficiaryID: [
              ...value.beneficiaryID,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        beneficiaryID: "File size is larger than 1MB",
      });
      data.onSuccess("ok");
    }
  };

  // const toTimestamp = (date, time) => {
  //   let dateOfIncident = new Date(date);
  //   let day = dateOfIncident.getDate();
  //   let month = dateOfIncident.getMonth() + 1;
  //   let year = dateOfIncident.getFullYear();

  //   let hours = time.split(":")[0];
  //   let minutes = time.split(":")[1];
  //   let seconds = time.split(":")[2];
  //   const datum = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
  //   return datum.toISOString();
  // };

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  const handleClaimsFormSubmit = async (e) => {
    let data = {};
    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.phoneNumber &&
      value.homeAddress &&
      value.emailAddress &&
      value.claimType === "death" &&
      value.deathType === "accident" &&
      value.policeReport.length > 0 &&
      value.trueClaims === true
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        phoneNumber: value.phoneNumber,
        policyNumber: value.policyNumber,
        emailAddress: value.emailAddress,
        address: value.homeAddress,
        type: value.claimType,
        causeOfDeath: value.deathType,
        incidentDate: value.incidentDate,
        description: value.description,
        deathCertificate: value.deathCertificate[0],
        medicalCertificate: value.medicalCertificate[0],
        policeReport: value.policeReport[0],
        beneficiaryIdCard: value.beneficiaryID[0],
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.phoneNumber &&
      value.homeAddress &&
      value.emailAddress &&
      value.claimType === "death" &&
      value.deathType === "natural" &&
      value.trueClaims === true
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        phoneNumber: value.phoneNumber,
        policyNumber: value.policyNumber,
        emailAddress: value.emailAddress,
        address: value.homeAddress,
        type: value.claimType,
        causeOfDeath: value.deathType,
        incidentDate: value.incidentDate,
        description: value.description,
        deathCertificate: value.deathCertificate[0],
        medicalCertificate: value.medicalCertificate[0],
        policeReport: value.policeReport[0],
        beneficiaryIdCard: value.beneficiaryID[0],
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.phoneNumber &&
      value.homeAddress &&
      value.emailAddress &&
      value.claimType === "maturity" &&
      value.accountName &&
      value.accountNumber &&
      value.bankName
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.emailAddress,
        address: value.homeAddress,
        type: value.claimType,
        accountName: value.accountName,
        accountNumber: value.accountNumber,
        bankName: value.bankName,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.phoneNumber &&
      value.homeAddress &&
      value.emailAddress &&
      value.claimType === "surrender/path-withdrawal" &&
      value.accountName &&
      value.accountNumber &&
      value.bankName
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.emailAddress,
        address: value.homeAddress,
        type: value.claimType,
        accountName: value.accountName,
        accountNumber: value.accountNumber,
        bankName: value.bankName,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.emailAddress &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "disablement" // && value.description
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.emailAddress,
        address: value.homeAddress,
        description: value.description,
        type: value.claimType,
        medicalReport: value.medicalReport[0],
        medicalBills: value.medicalBill[0],
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.emailAddress &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "critical illness" // && value.description
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.emailAddress,
        address: value.homeAddress,
        description: value.description,
        type: value.claimType,
        medicalReport: value.medicalReport[0],
        medicalBills: value.medicalBill[0],
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.emailAddress &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "loan" &&
      value.accountName &&
      value.accountNumber &&
      value.bankName
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.emailAddress,
        address: value.homeAddress,
        type: value.claimType,
        accountName: value.accountName,
        accountNumber: value.accountNumber,
        bankName: value.bankName,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.emailAddress &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "loss of job"
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.emailAddress,
        address: value.homeAddress,
        type: value.claimType,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (Object.keys(data).length === 0) {
      setIsLoading(false);
      setError({
        ...error,
        submitError:
          "Some fields have not been filled. Please fill the necessary fields.",
      });
    }

    const response = await axios({
      method: "POST",
      url: `${HeirsFetchPostsUrl}/claims/life`,
      data,
    });
    if (response && response.data.status === "success") {
      setIsLoading(false);
      setIsSuccessful(true);
    } else {
      setIsLoading(false);
      setIsSuccessful(false);
    }
  };

  return {
    disabledDate,
    error,
    handleAccountNamesChange,
    handleAccountNumberChange,
    handleBeneficiaryFileUpload,
    handleCheckedChange,
    handleClaimsFormSubmit,
    handleClaimSelectChange,
    handleDateChange,
    handleDeathFileUpload,
    handleDeathSelectChange,
    handleDescriptionChange,
    handleEmailChange,
    handleInsuredNameChange,
    handleLocationChange,
    handleMedicalBillFileUpload,
    handleMedicalFileUpload,
    handleMedicalReportFileUpload,
    handleNumberChange,
    handlePhoneNumberChange,
    handlePoliceFileUpload,
    handlePolicyNumberChange,
    handleUploadChange,
    isDisabled,
    isLoading,
    isSuccessful,
    onBeneficiaryIDUpload,
    onDeathCertificateUpload,
    onMedicalBillUpload,
    onMedicalCertificateUpload,
    onMedicalReportUpload,
    onPoliceReportUpload,
    value,
  };
};

export default useClaims;
