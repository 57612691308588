import styled from "styled-components";
import * as Colors from "../../utils/colors";

export const Text = styled("p")`
  color: ${Colors.PRIMARY};
  margin: 25px 0 10px;
  text-align: center;
`;

export const Title = styled("h1")`
  font-size: 45px;
  text-align: center;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    & {
      font-size: 25px;
    }
  }
`;

export const SubTitle = styled("h3")`
  span {
    color: ${Colors.DARK};
  }

  color: ${Colors.PRIMARY};
  text-align: center;
  margin: 0 0 45px;
  font-weight: 600;
`;

export const Wrapper = styled("div")`
  padding: 50px 0;

  ${({ filled }) =>
    filled &&
    `
    ${Title} {
     color: ${Colors.WHITE};
    }
    
    ${SubTitle} {
     color: ${Colors.WHITE};
    }
  `}
`;
