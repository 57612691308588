import React, { useEffect } from "react";
import wholeLife from "../../assets/images/surgery-care.png";
import { Link } from "react-router-dom";
import {
  Text,
  Title,
  Wrapper,
  List,
  PlanChatTitle,
  PlanChatWrapper,
} from "./styles";
import { HeirsDashboardUrl } from "../../utils/constant";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });

  return (
    <Wrapper id="faq">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th
                  className="ant-table-cell"
                  style={{ width: "50%" }}
                  colspan="4"
                >
                  <Title>USSD FAQs</Title>
                </th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" colspan="4">
                  <Text>
                    <ol>
                      <li style={{ margin: "20px 5px" }}>
                        <b>What is Heirs Life USSD code?</b> The Heirs Life USSD
                        code is *1100#
                      </li>
                      <li style={{ margin: "20px 5px" }}>
                        <b>What can I do with Heirs Life USSD?</b> You can do
                        the following:{" "}
                        <ul>
                          <li>• Renew your policies.</li>
                          <li>• View your policies.</li>
                          <li>• Get information on plans.</li>
                          <li>• Make complaints.</li>
                          <li>• Process Claims.</li>
                        </ul>
                      </li>
                      <li style={{ margin: "20px 5px" }}>
                        <b>Can I edit my premium during policy renewal?</b> The
                        No, you can't. You can only pay the exact premium for
                        the policy due to be renewed.
                      </li>
                      <li style={{ margin: "20px 5px" }}>
                        <b>
                          How much will I be charged for using this service?
                        </b>{" "}
                        Only N53.75 for Transaction charges + VAT
                      </li>
                      <li style={{ margin: "20px 5px" }}>
                        <b>Can I pay from any bank account?</b> No, only the
                        banks listed below are available for now.{" "}
                        <ul>
                          <li>• First bank</li>
                          <li>• Access bank</li>
                          <li>• UBA</li>
                          <li>• Ecobank</li>
                          <li>• Zenith bank</li>
                          <li>• Fidelity</li>
                          <li>• Heritage</li>
                          <li>• Polaris</li>
                          <li>• Suntrust</li>
                          <li>• Wema bank</li>
                          <li>• Union bank</li>
                        </ul>
                      </li>
                      <li style={{ margin: "20px 5px" }}>
                        <b>
                          Can I use the service if I am not registered on my
                          bank's USSD service??
                        </b>{" "}
                        No, you must be registered on your bank's USSD platform
                        to use this service.
                      </li>
                      <li style={{ margin: "20px 5px" }}>
                        <b>
                          Will I create a special PIN to process transactions?
                        </b>{" "}
                        No, your bank's USSD PIN will be used to process
                        transactions.
                      </li>
                      <li style={{ margin: "20px 5px" }}>
                        <b>
                          Can I use a separate phone number to use the service?{" "}
                        </b>{" "}
                        No, the phone number on your policy must be the same
                        number registered on your bank's USSD.
                      </li>
                      <li style={{ margin: "20px 5px" }}>
                        <b>
                          When will my policy be updated after making payment?
                        </b>{" "}
                        Within 24 hours.
                      </li>
                      <li style={{ margin: "20px 5px" }}>
                        <b>
                          What happens if I make a payment and my policy is not
                          updated?
                        </b>{" "}
                        Simply send a mail to wecare@heirslifeassurance.com or
                        call 0700 434 7754.
                      </li>
                    </ol>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
