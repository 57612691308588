import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import groupLife from "../../assets/images/group.png";
// import { HeirsDashboardUrl } from "../../utils/constant";
import { Text, Title, Wrapper, List } from "../PlanPicker/styles";
import { PlanChatTitle, PlanChatWrapper } from "../PlansList/style";
// import { Mixpanel } from "./../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  // const checkClickEvent = (e) => {
  //   if (e.target.id === "purchaseStatus") {
  //     setPurchaseStatus();
  //   }
  // };

  // const setPurchaseStatus = () => {
  //   localStorage.setItem("attempted_purchase", "true");
  // };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    // localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    // window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="group-life">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <img src={groupLife} alt="group life plan" />
                </th>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <Title>Statutory Group Life</Title>
                  {/* <a
                    onClick={Mixpanel.track("Statutory Group Life Buy")}
                    href={`${HeirsDashboardUrl}/protection-type`}
                  >
                    Buy Now
                  </a> */}
                </th>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <Title>Non-Statutory Group Life</Title>
                  {/* <a
                    onClick={Mixpanel.track("Non-Statutory Group Life Buy")}
                    href={`${HeirsDashboardUrl}/protection-type`}
                  >
                    Buy Now
                  </a> */}
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>For Whom?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Employers of Labour</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Clubs, associations and cooperatives</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Payment Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Renewable Annually</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Renewable Annually</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Benefits</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Life insurance benefit is paid in the event of the death of
                    employee
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Life insurance benefit is paid in the event of the death of
                    a member
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Amount paid to beneficiary if there's loss of life
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Minimum of three times of the employee's annual emolument
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>To be chosen by the group</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Documents Required</Text>
                </td>
                <td className="ant-table-cell" colspan="2">
                  <List>
                    <li>Duly Filled Proposal Form.</li>
                    <li>Evidence of Payment.</li>
                    <li>
                      Valid Means of ID - Driver License, Voter's Card, NIMC,
                      National Passport of one of the Directors.
                    </li>
                    <li>
                      Proof of Residence - Utility Bill, Front page of the
                      customer's address (same address as indicated on the form
                      or portal), Local Govt. Levy.
                    </li>
                    <li>
                      Duly Filled CTR, Financial Questionnaire, Address
                      Verification Form, Bank Statement not more than 6-months
                      (if Premium is more than N5 million).
                    </li>
                  </List>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
