import React from "react";
import Banner from "../../components/ProductBanner";
import MortgagePlanPicker from "../../components/MortgagePlanPicker";
import backgroundImage from "../../assets/images/heirs-mortgage.png";

const Index = () => {
  const bannerPayload = {
    title: "Mortgage Plan",
    descriptions: [
      "Securing your own home is a significant milestone.",
      "However, life’s unexpected events can prevent you and your family from fully acquiring your home, especially if it has been acquired through mortgage. ",
      "The Heirs Mortgage plan saves your family from the financial burden of paying an outstanding mortgage if the unfortunate happens to you. In such a case as this, Heirs Life will pay the outstanding mortgage to ensure that your family still acquires the home you worked hard for. ",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#mortgage",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <MortgagePlanPicker />
    </div>
  );
};

export default Index;
