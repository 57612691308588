import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/ussd.JPG";
import Ussd from "./ussd.js";
// import HospitalCashPlanPicker from "../../components/HospitalCashPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "USSD",
    descriptions: ["Frequently Asked Questions about the Heirs Life USSD Code"],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#faq",
    // linkText: "Watch how it works",
    linkText: "FAQ",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <Ussd />
    </div>
  );
};

export default Index;
