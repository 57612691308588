import React from "react";
import Banner from "../../components/ProductBanner";
import backgroundImage from "../../assets/images/couplesplan.png";
import CouplesPlanPicker from "../../components/CouplesPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Couples Plan",
    descriptions: [
      "This plan is for married or unmarried couples, who want to build their finances together while protecting their personal investments.",
      "They both save money together for a period. Their savings attract competitive interest. If separation or divorce happens, the pool of money is split between the couple according to the terms they must have agreed at the onset of the plan.",
      "The plan comes with a life insurance addition that pays either couple’s listed beneficiary an agreed amount of money as financial support, in addition to the money and interest saved, if the unfortunate happens.",
      "This way, the person’s family is cared for, even in the event of loss.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#couples",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <CouplesPlanPicker />
    </div>
  );
};

export default Index;
