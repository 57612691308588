import React from "react";
import Banner from "../../components/WhistleBanner";
import WhistleBlowing from "../../components/WhistleBlowing";
// import backgroundImage from "../../assets/images/Flexi&comprehensive_motor.png";

const Index = () => {
  const bannerPayload = {
    title: " The Whistle Blowing Policy",
    descriptions: [
      "Heirs Life Assurance Limited seeks to create an environment free of unfair practices and unethical conduct by laying down the highest standards of conduct for its employees.",
    ],
    // backgroundImage,
    // linkPath: "https://www.youtube.com/watch?v=6v_fReAkzO8",
    // linkPath: "#comprehensive",
    // linkText: "Watch how it works",
    // linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <WhistleBlowing />
    </div>
  );
};

export default Index;
